import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

export default function CheckoutError() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white rounded-3xl shadow-lg p-8 max-w-md w-full mx-4">
        <div className="text-center">
          <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <AlertTriangle className="w-8 h-8 text-red-500" />
          </div>
          <h1 className="text-2xl font-bold text-[#2C3E50] mb-4">
            Oops! Something went wrong
          </h1>
          <p className="text-gray-600 mb-8">
            We encountered an error while processing your payment. Please try again or contact support if the problem persists.
          </p>
          <div className="space-y-4">
            <button
              onClick={() => navigate('/checkout')}
              className="w-full py-3 px-6 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
            >
              Try Again
            </button>
            <button
              onClick={() => navigate('/my-itineraries')}
              className="w-full py-3 px-6 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-colors"
            >
              Back to My Itineraries
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}