import React from 'react';
import { MapPin, Brain, CircleFadingArrowUp } from 'lucide-react';

const steps = [
  {
    icon: MapPin,
    title: 'Enter Your Dream Destination',
    description: 'Tell us where you want to go and answer a few questions about your travel style and preferences.'
  },
  {
    icon: Brain,
    title: 'AI-Powered Travel Research',
    description: 'Our AI analyzes thousands of recommendations to create an itinerary just for you.'
  },
  {
    icon: CircleFadingArrowUp,
    title: 'Upgrade your Itinerary',
    description: 'On top of our AI results, you can upgrade your itinerary to have a personalized and precise expert made itinerary'
  }
];

export default function ProcessSection() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-[#2C3E50] mb-12">
          How It Works
        </h2>
        
        <div className="grid md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center group">
              <div className="mb-6 relative">
                <div className="w-20 h-20 mx-auto bg-[#E53E3E] rounded-full flex items-center justify-center transform transition-transform group-hover:scale-110">
                  <step.icon size={32} className="text-white" />
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 left-[60%] w-full h-0.5 bg-gray-200">
                    <div className="absolute top-0 left-0 h-full bg-[#E53E3E] transition-all duration-500 group-hover:w-full" style={{ width: '0%' }} />
                  </div>
                )}
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#2C3E50]">{step.title}</h3>
              <p className="text-gray-600 max-w-sm mx-auto">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}