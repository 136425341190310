import { useState, useEffect, useRef } from 'react';

declare global {
  interface Window {
    google: typeof google;
    initGooglePlaces: () => void;
  }
}

export function usePlacesAutocomplete() {
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | null>(null);
  const scriptId = 'google-maps-script';

  // Initialize Places service when Google Maps is loaded
  const initPlacesService = () => {
    try {
      if (window.google?.maps?.places) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
      }
    } catch (err) {
      console.error('Error initializing Places service:', err);
      setError('Failed to initialize Places service');
    }
  };

  useEffect(() => {
    // Check if script already exists
    const existingScript = document.getElementById(scriptId);
    
    // Clear any existing predictions when component mounts
    setPredictions([]);
    
    if (existingScript) {
      // If the script exists and Google is loaded, initialize immediately
      if (window.google?.maps?.places) {
        initPlacesService();
      }
      return;
    }

    // Initialize the Google Places service
    window.initGooglePlaces = () => {
      initPlacesService();
    };

    // Load the Google Maps JavaScript API
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB6IxHK-7TJpuWSRtFmt5esVeiFXxSMS6E&libraries=places&callback=initGooglePlaces`;
    script.async = true;
    script.defer = true;
    script.onerror = () => {
      console.error('Failed to load Google Maps script');
      setError('Failed to load Google Maps');
    };
    document.head.appendChild(script);

    return () => {
      // Only remove the script if component is unmounted and no other components are using it
      const script = document.getElementById(scriptId);
      if (script && document.querySelectorAll('[data-uses-places]').length === 0) {
        document.head.removeChild(script);
        delete window.initGooglePlaces;
      }
    };
  }, []);

  const getPlacePredictions = async (input: string) => {
    if (!input.trim()) {
      setPredictions([]);
      setLoading(false);
      return;
    }
    
    if (!autocompleteService.current) {
      initPlacesService();
      if (!autocompleteService.current) {
        setError('Places service not available');
        return;
      }
    }

    setLoading(true);
    setError(null);

    try {
      const request: google.maps.places.AutocompletionRequest = {
        input,
        types: ['(cities)'], // Restrict to cities only
        sessionToken: sessionToken.current,
      };

      const predictions = await new Promise<google.maps.places.AutocompletePrediction[]>(
        (resolve, reject) => {
          autocompleteService.current?.getPlacePredictions(
            request,
            (
              predictions: google.maps.places.AutocompletePrediction[] | null,
              status: google.maps.places.PlacesServiceStatus
            ) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                resolve(predictions);
              } else {
                reject(status);
              }
            }
          );
        }
      );
      
      setPredictions(predictions);
      setError(null);

    } catch (err) {
      console.error('Error fetching predictions:', err);
      setError('Failed to fetch predictions');
      setPredictions([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    predictions,
    loading,
    error,
    getPlacePredictions,
  };
}