import React, { useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

export default function Privacy() {
  const lastUpdated = '2024-03-20';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-[#2C3E50] mb-8">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-8">Last Updated: {lastUpdated}</p>

      <div className="prose prose-lg max-w-none">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">1. Data Collection</h2>
          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">1.1 Personal Information</h3>
          <p className="text-gray-600 mb-4">We collect:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Name and contact information</li>
            <li>Account credentials</li>
            <li>Travel preferences and history</li>
            <li>Payment information</li>
            <li>Communication records</li>
          </ul>

          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">1.2 Automatically Collected Data</h3>
          <p className="text-gray-600 mb-4">We automatically collect:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Device information</li>
            <li>IP address and location data</li>
            <li>Browser type and settings</li>
            <li>Usage patterns and preferences</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">2. Data Collection Methods</h2>
          <p className="text-gray-600 mb-4">We collect information through:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Direct user input</li>
            <li>Automated tracking technologies</li>
            <li>Third-party services and APIs</li>
            <li>Customer support interactions</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">3. Use of Data</h2>
          <p className="text-gray-600 mb-4">Your data is used for:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Providing and improving our services</li>
            <li>Personalizing user experience</li>
            <li>Processing transactions</li>
            <li>Communication about services</li>
            <li>Analytics and service optimization</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">4. Data Sharing</h2>
          <p className="text-gray-600 mb-4">We may share data with:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Service providers and partners</li>
            <li>Legal authorities when required</li>
            <li>Third-party analytics services</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">5. Cookie Usage</h2>
          <p className="text-gray-600 mb-4">We use cookies for:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Authentication and security</li>
            <li>Preferences and settings</li>
            <li>Analytics and performance</li>
            <li>Advertising and targeting</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">6. Your Rights</h2>
          <p className="text-gray-600 mb-4">You have the right to:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Access your personal data</li>
            <li>Request data correction</li>
            <li>Request data deletion</li>
            <li>Withdraw consent</li>
            <li>Data portability</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">7. Contact Information</h2>
          <p className="text-gray-600 mb-4">
            For privacy-related inquiries, please contact our Data Protection Officer at:
          </p>
          <p className="text-gray-600">
            Email: privacy@eztravelplan.com<br />
            Address: [Company Address]<br />
            Phone: [Phone Number]
          </p>
        </section>
      </div>

      <button
        onClick={scrollToTop}
        className="fixed bottom-8 right-8 bg-[#4A90E2] text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
        aria-label="Scroll to top"
      >
        <ArrowUp size={24} />
      </button>
    </div>
  );
}