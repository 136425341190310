import React, { useState } from 'react';
import { Check, Star, Users, Crown, MessageSquare, Infinity } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../hooks/useAuthState';
import PremiumUpgradeModal from './PremiumUpgradeModal';
import ConsultantUpgradeModal from './ConsultantUpgradeModal';

const features = {
  premium: [
    'Highly personalized itinerary',
    'Hidden gems and local spots',
    'Priority support',
    'Real-time itinerary updates',
    'Two day minimum per itinerary'
  ],
  unlimited: [
    'Full access to AI itineraries',
    'Unlimited creation of AI itineraries',
    'Priority queue processing',
    'Discount on Premium travel guides'
  ],
  consultant: [
    'Personal travel consultant',
    'Custom experience design',
    'Top Priority support',
    'Real-time plan adjustments',
    'Concierge service whenever available',

  ]
};

interface PricingSectionProps {
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
  setShowContactForm: (isOpen: boolean) => void;
}

export default function PricingSection({
  setShowAuthModal,
  setShowContactForm
}: PricingSectionProps) {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showConsultantModal, setShowConsultantModal] = useState(false);

  const handlePremiumClick = () => {
    if (user) {
      setShowPremiumModal(true);
    } else {
      setShowAuthModal('signup');
    }
  };

  const handleUnlimitedClick = () => {
    if (user) {
      navigate('/my-itineraries');
    } else {
      setShowAuthModal('signup');
    }
  };

  return (
    <section id="pricing" className="py-16 bg-gray-50 scroll-mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-[#502c2c] mb-4">
          Choose Your Plan
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          From personalized itineraries to full-service travel consulting, we have the perfect plan for your needs
        </p>

        <div className="grid md:grid-cols-3 gap-8">
          {/* Premium Plan */}
          <div className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-semibold text-[#502c2c] mb-4">Premium</h3>
              <div className="text-4xl font-bold text-[#E53E3E] mb-2">$29.95</div>
              <p className="text-gray-600">per day of itinerary</p>
            </div>

            <ul className="space-y-4 mb-8">
              {features.premium.map((feature, index) => (
                <li key={index} className="flex items-center gap-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span className="text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={handlePremiumClick}
              className="w-full py-3 px-6 rounded-full bg-red-200 hover:bg-red-300 text-[#502c2c] font-semibold transition-colors flex items-center justify-center gap-2"
              aria-label="Get Premium plan">
              <Crown size={20} />
              Get Premium
            </button>
          </div>

          {/* Unlimited Plan */}
          <div className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-xl transition-shadow relative">
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
              <div className="bg-[#E53E3E] text-white px-4 py-1 rounded-full text-sm font-semibold flex items-center gap-1">
                <Star size={16} />
                Most Popular
              </div>
            </div>

            <div className="text-center mb-8">
              <h3 className="text-2xl font-semibold text-[#502c2c] mb-4">Unlimited</h3>
              <div className="text-4xl font-bold text-[#E53E3E] mb-2">$49.95</div>
              <p className="text-gray-600">per year</p>
            </div>

            <ul className="space-y-4 mb-8">
              {features.unlimited.map((feature, index) => (
                <li key={index} className="flex items-center gap-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span className="text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={handleUnlimitedClick}
              className="w-full py-3 px-6 rounded-full bg-gradient-to-r from-[#E53E3E] to-red-700 hover:from-red-600 hover:to-red-800 text-white font-semibold transition-colors flex items-center justify-center gap-2"
              aria-label="Get Unlimited plan">
              <Infinity size={20} />
              Get Unlimited
            </button>
          </div>

          {/* Consultant Plan */}
          <div className="bg-white rounded-3xl shadow-lg p-8 hover:shadow-xl transition-shadow">
            <div className="text-center mb-8">
              <h3 className="text-2xl font-semibold text-[#502c2c] mb-4">Consultant</h3>
              <div className="text-4xl font-bold text-[#E53E3E] mb-2">$49.95</div>
              <p className="text-gray-600">per hour of consultation</p>
            </div>

            <ul className="space-y-4 mb-8">
              {features.consultant.map((feature, index) => (
                <li key={index} className="flex items-center gap-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span className="text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => setShowConsultantModal(true)}
              className="w-full py-3 px-6 rounded-full bg-red-100 hover:bg-red-200 text-[#502c2c] font-semibold transition-colors flex items-center justify-center gap-2">
              <MessageSquare size={20} />
              Ask for a Consultant
            </button>
          </div>
        </div>
      </div>
      
      <PremiumUpgradeModal
        isOpen={showPremiumModal}
        onClose={() => setShowPremiumModal(false)}
      />
      
      <ConsultantUpgradeModal
        isOpen={showConsultantModal}
        onClose={() => setShowConsultantModal(false)}
        setShowAuthModal={setShowAuthModal}
      />
    </section>
  );
}