import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Hero from './components/Hero';
import CustomerShowcase from './components/CustomerShowcase';
import ProcessSection from './components/ProcessSection';
import FeaturesGrid from './components/FeaturesGrid';
import PricingSection from './components/PricingSection';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import ContactFAB from './components/ContactFAB';
import AuthHeader from './components/AuthHeader';
import MyItineraries from './pages/MyItineraries';
import Footer from './components/Footer';
import Terms from './pages/legal/Terms';
import Privacy from './pages/legal/Privacy';
import DataProtection from './pages/legal/DataProtection';
import Checkout from './pages/Checkout';
import Success from './pages/checkout/Success';
import Cancel from './pages/checkout/Cancel';
import Error from './pages/checkout/Error';
import { useAuthState } from './hooks/useAuthState';
import ContactFormModal from './components/ContactFormModal';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthState();
  
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4A90E2]"></div>
      </div>
    );
  }
  
  if (!user) {
    return <Navigate to="/" replace />;
  }
  
  return <>{children}</>;
}

interface LandingPageProps {
  showAuthModal: 'signin' | 'signup' | null;
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
  showContactForm: boolean;
  setShowContactForm: (value: boolean) => void;
  showMultiDestModal: boolean;
  setShowMultiDestModal: (value: boolean) => void;
}

function LandingPage({
  showAuthModal,
  setShowAuthModal,
  showContactForm,
  setShowContactForm,
  showMultiDestModal,
  setShowMultiDestModal
}: LandingPageProps) {
  return (
    <>
      <Hero 
        setShowAuthModal={setShowAuthModal}
        showMultiDestModal={showMultiDestModal}
        setShowMultiDestModal={setShowMultiDestModal}
      />
      <CustomerShowcase />
      <ProcessSection />
      <FeaturesGrid />
      <PricingSection 
        setShowAuthModal={setShowAuthModal}
        setShowContactForm={setShowContactForm}
      />
      <FAQ />
      <Contact />
      <ContactFAB
        isOpen={showContactForm}
        setIsOpen={setShowContactForm}
      />
    </>
  );
}

function App() {
  const [showAuthModal, setShowAuthModal] = useState<'signin' | 'signup' | null>(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedItinerary, setSelectedItinerary] = useState<string | null>(null);
  const [showFAB, setShowFAB] = useState(true);
  const [showMultiDestModal, setShowMultiDestModal] = useState(false);

  return (
    <Router>
      <div className="min-h-screen">
        <AuthHeader 
          showAuthModal={showAuthModal} 
          setShowAuthModal={setShowAuthModal}
          showMultiDestModal={showMultiDestModal}
          setShowMultiDestModal={setShowMultiDestModal}
        />
        <Routes>
          <Route path="/" element={
            <LandingPage 
              showAuthModal={showAuthModal}
              setShowAuthModal={setShowAuthModal}
              showContactForm={showContactForm}
              setShowContactForm={setShowContactForm}
              showMultiDestModal={showMultiDestModal}
              setShowMultiDestModal={setShowMultiDestModal}
            />
          } />
          <Route
            path="/my-itineraries"
            element={
              <ProtectedRoute>
                <MyItineraries
                  showContactForm={showContactForm}
                  setShowContactForm={setShowContactForm}
                  setSelectedItinerary={setSelectedItinerary}
                  setShowAuthModal={setShowAuthModal}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/data-protection" element={<DataProtection />} />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout/success"
            element={
              <ProtectedRoute>
                <Success />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout/cancel"
            element={
              <ProtectedRoute>
                <Cancel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout/error"
            element={
              <ProtectedRoute>
                <Error />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ContactFormModal
          isOpen={showContactForm}
          setIsOpen={setShowContactForm}
          selectedItinerary={selectedItinerary}
          setSelectedItinerary={setSelectedItinerary}
        />
        {showFAB && (
          <ContactFAB
            isOpen={showContactForm}
            setIsOpen={setShowContactForm}
          />
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
