import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useAuthState } from '../hooks/useAuthState';
import { useState } from 'react';

declare global {
  interface Window {
    Stripe?: (key: string) => any;
  }
}

const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51QjP22ENBBUw7VQ7uPGixlirS2XpGo00Mqa7wA8b9VeNhZAIxshA0FseOZbNM6Xa8zFrOGFMLfkX9aqfHc1oaEaM00KUQmar3l';

export default function Checkout() {
  const navigate = useNavigate();
  const { user, loading } = useAuthState();
  const [loadingStripe, setLoadingStripe] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const stripePromiseRef = useRef<Promise<any> | null>(null);

  useEffect(() => {
    let isSubscribed = true;

    const loadStripe = async () => {
      try {
        if (!stripePromiseRef.current) {
          console.log('Loading Stripe.js script');
          const script = document.createElement('script');
          script.src = 'https://js.stripe.com/v3/';
          script.async = true;

          stripePromiseRef.current = new Promise((resolve, reject) => {
            script.onload = () => {
              if (!window.Stripe) {
                reject(new Error('Stripe.js failed to load'));
                return;
              }
              resolve(window.Stripe(STRIPE_PUBLISHABLE_KEY));
            };
            script.onerror = () =>
              reject(new Error('Failed to load Stripe.js'));
          });

          document.body.appendChild(script);
        }

        const stripe = await stripePromiseRef.current;
        if (isSubscribed) {
          setLoadingStripe(false);
          await initializeCheckout(stripe);
        }
      } catch (error) {
        if (isSubscribed) {
          console.error('Error loading Stripe:', error);
          setError(
            error instanceof Error
              ? error.message
              : 'An unexpected error occurred'
          );
          navigate('/checkout/error');
        }
      }
    };

    if (!loading && user) {
      loadStripe();
    }

    return () => {
      isSubscribed = false;
    };
  }, [user, loading, navigate]);

  const initializeCheckout = async (stripe) => {
    try {
      const baseUrl = window.location.origin;
      console.log('Base URL for redirect:', baseUrl);
      const config = {
        lineItems: [{ price: 'price_1QooskENBBUw7VQ7NpUhFZzR', quantity: 1 }],
        mode: 'subscription',
        successUrl: `${baseUrl}/checkout/success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${baseUrl}/checkout/cancel`,
        customerEmail: user?.email,
        billingAddressCollection: 'auto',
        submitType: 'auto',
      };

      const result = await stripe.redirectToCheckout(config);

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Checkout error:', error);
      setError(
        error instanceof Error ? error.message : 'Failed to initialize checkout'
      );
      navigate('/checkout/error');
    }
  };

  console.log('Rendering Checkout component');

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <div className="flex items-center justify-center mb-4">
          <Loader2 className="w-12 h-12 text-[#E53E3E] animate-spin" />
        </div>
        <h1 className="text-2xl font-bold text-[#2C3E50] mb-2">
          Preparing Your Checkout...
        </h1>
        <p className="text-gray-600">
          {loadingStripe
            ? 'Loading payment system...'
            : 'Redirecting to secure payment page...'}
        </p>
        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-lg max-w-md mx-auto">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
