import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, Loader2 } from 'lucide-react';
import { useAuthState } from '../../hooks/useAuthState';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

export default function CheckoutSuccess() {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const updateSubscriptionStatus = async () => {
      if (!user) return;

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');

        if (!sessionId) {
          throw new Error('No session ID found');
        }

        // Update user document with subscription status
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          subscriptionStatus: 'active',
          subscriptionType: 'unlimited',
          subscriptionUpdatedAt: new Date(),
          stripeSessionId: sessionId
        });

        setLoading(false);
        
        // Redirect to itineraries after a short delay
        setTimeout(() => {
          navigate('/my-itineraries');
        }, 10000);

      } catch (err) {
        console.error('Error updating subscription:', err);
        setError(err instanceof Error ? err.message : 'Failed to update subscription');
        setLoading(false);
      }
    };

    updateSubscriptionStatus();
  }, [user, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <Loader2 className="w-12 h-12 text-[#E53E3E] animate-spin mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Activating Your Subscription
          </h2>
          <p className="text-gray-600">
            Please wait while we set up your unlimited access...
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-white rounded-3xl shadow-lg p-8 max-w-md w-full mx-4">
          <div className="text-center">
            <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <CheckCircle2 className="w-8 h-8 text-red-500" />
            </div>
            <h1 className="text-2xl font-bold text-[#2C3E50] mb-4">
              Subscription Error
            </h1>
            <p className="text-gray-600 mb-8">
              {error}. Please contact support if this issue persists.
            </p>
            <button
              onClick={() => navigate('/my-itineraries')}
              className="w-full py-3 px-6 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
            >
              Return to My Itineraries
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white rounded-3xl shadow-lg p-8 max-w-md w-full mx-4">
        <div className="text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <CheckCircle2 className="w-8 h-8 text-green-500" />
          </div>
          <h1 className="text-2xl font-bold text-[#2C3E50] mb-4">
            Welcome to Unlimited!
          </h1>
          <p className="text-gray-600 mb-8">
            Your subscription has been activated successfully. You now have access to unlimited AI-powered itineraries and premium features.
          </p>
          <button
            onClick={() => navigate('/my-itineraries')}
            className="w-full py-3 px-6 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
          >
            Start Planning Your Trips
          </button>
        </div>
      </div>
    </div>
  );
}