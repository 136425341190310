import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function useSubscriptionStatus(userId: string | undefined) {
  const [status, setStatus] = useState<'active' | 'inactive' | 'canceling' | null>(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setStatus(data?.subscriptionStatus || 'inactive');
          setSubscriptionEndDate(data?.subscriptionEndDate?.toDate?.().toISOString() || null);
        } else {
          setStatus('inactive');
          setSubscriptionEndDate(null);
        }
        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error('Error fetching subscription status:', err);
        setError('Failed to load subscription status');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  return { status, subscriptionEndDate, loading, error };
}