import React, { useState } from 'react';
import { MessageCircle } from 'lucide-react';

interface ContactFABProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function ContactFAB({ setIsOpen }: ContactFABProps) {
  return (
    <button
      onClick={() => setIsOpen(true)}
      className="fixed bottom-6 right-6 w-14 h-14 bg-[#E53E3E] text-white rounded-full shadow-lg hover:bg-red-600 transition-colors flex items-center justify-center z-40"
      aria-label="Contact Us"
    >
      <MessageCircle size={24} />
    </button>
  );
}