import React, { useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

export default function Terms() {
  const lastUpdated = '2024-03-20';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-[#2C3E50] mb-8">Terms of Use</h1>
      <p className="text-sm text-gray-600 mb-8">Last Updated: {lastUpdated}</p>

      <div className="prose prose-lg max-w-none">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">1. Agreement to Terms</h2>
          <p className="text-gray-600 mb-4">
            By accessing and using EZTravelPlan's website and services, you agree to be bound by these Terms of Use. If you disagree with any part of these terms, you may not access our services.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">2. User Rights and Responsibilities</h2>
          <p className="text-gray-600 mb-4">
            Users are granted a limited, non-exclusive, non-transferable license to access and use our services for personal travel planning purposes.
          </p>
          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">2.1 Account Creation</h3>
          <p className="text-gray-600 mb-4">
            Users must provide accurate, current, and complete information during registration and maintain the security of their account credentials.
          </p>
          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">2.2 User Content</h3>
          <p className="text-gray-600 mb-4">
            Users retain ownership of their content but grant EZTravelPlan a license to use, store, and share this content for service provision purposes.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">3. Prohibited Activities</h2>
          <p className="text-gray-600 mb-4">Users must not:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Use the service for any illegal purposes</li>
            <li>Attempt to gain unauthorized access to our systems</li>
            <li>Interfere with other users' access to the service</li>
            <li>Upload malicious code or content</li>
            <li>Impersonate other users or entities</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">4. Intellectual Property Rights</h2>
          <p className="text-gray-600 mb-4">
            All content, features, and functionality of our service, including but not limited to text, graphics, logos, and software, are owned by EZTravelPlan and protected by intellectual property laws.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">5. Termination</h2>
          <p className="text-gray-600 mb-4">
            We may terminate or suspend your account and access to our services immediately, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">6. Limitation of Liability</h2>
          <p className="text-gray-600 mb-4">
            EZTravelPlan shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the service.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">7. Changes to Terms</h2>
          <p className="text-gray-600 mb-4">
            We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through our service.
          </p>
        </section>
      </div>

      <button
        onClick={scrollToTop}
        className="fixed bottom-8 right-8 bg-[#4A90E2] text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
        aria-label="Scroll to top"
      >
        <ArrowUp size={24} />
      </button>
    </div>
  );
}