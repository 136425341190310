import React from 'react';
import { Mail } from 'lucide-react';

export default function Contact() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-[#2C3E50] mb-6">
          Contact Us
        </h2>
        <p className="text-gray-600 mb-8">
          Have questions or need assistance? We're here to help!
        </p>
        <a
          href="mailto:contact@eztravelplan.com"
          className="inline-flex items-center gap-2 bg-[#E53E3E] text-white px-6 py-3 rounded-3xl hover:bg-red-600 transition-colors"
        >
          <Mail size={20} />
          contact@eztravelplan.com
        </a>
      </div>
    </section>
  );
}