import React, { useState, useEffect, useRef } from 'react';
import { Search, ChevronRight } from 'lucide-react';
import { usePlacesAutocomplete } from '../hooks/usePlacesAutocomplete';
import MultiDestinationModal from './MultiDestinationModal';
import TravelPreferencesModal from './TravelPreferencesModal';
import { useAuthState } from '../hooks/useAuthState';

interface HeroProps {
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
  showMultiDestModal: boolean;
  setShowMultiDestModal: (value: boolean) => void;
}

export default function Hero({ 
  setShowAuthModal,
  showMultiDestModal,
  setShowMultiDestModal
}: HeroProps) {
  const { user } = useAuthState();
  const [destination, setDestination] = useState('');
  const [showPredictions, setShowPredictions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const { predictions, loading, getPlacePredictions } = usePlacesAutocomplete();
  const [selectedDestinations, setSelectedDestinations] = useState<string[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowPredictions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDestination(value);
    getPlacePredictions(value);
    setShowPredictions(true);
  };

  const handlePredictionClick = (prediction: google.maps.places.AutocompletePrediction) => {
    setDestination(prediction.description);
    setShowPredictions(false);
  };

  const handleSearchClick = () => {
    setValidationError(null);
    
    if (!destination.trim()) {
      setValidationError('Please enter a destination');
      return;
    }
    
    if (!user) {
      // Store the destination for after authentication
      sessionStorage.setItem('pendingDestination', destination);
      setShowAuthModal('signup');
      return;
    }
    
    setShowMultiDestModal(true);
  };

  const handleMultiDestContinue = (destinations: string[]) => {
    setShowMultiDestModal(false);
    setSelectedDestinations(destinations);
    setShowModal(true);
  };

  const handleTravelPreferencesClose = () => {
    setShowModal(false);
    setShowMultiDestModal(true);
  };

  return (
    <div id="hero-section" className="relative h-[80vh] min-h-[600px] w-full">
      <div 
        data-uses-places
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2021&q=80")',
        }}
      >
        <div className="absolute inset-0 bg-black/40" />
      </div>
      
      <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
          Your Dream Trip, Expertly Planned
        </h1>
        <p className="text-xl text-white mb-12 max-w-2xl">
          AI-powered travel planning that creates personalized itineraries in minutes
        </p>
        
        <div className="w-full max-w-2xl">
          <div className="relative flex flex-col sm:block gap-4" ref={searchContainerRef}>
            <input
              type="text"
              value={destination}
              onChange={(e) => {
                handleInputChange(e);
                setValidationError(null);
              }}
              onFocus={() => setShowPredictions(true)}
              placeholder="Where do you want to go?"
              className={`w-full px-6 py-4 text-lg rounded-3xl shadow-lg focus:outline-none focus:ring-2 ${
                validationError ? 'ring-2 ring-red-500' : 'focus:ring-red-500'
              } sm:pr-36 bg-white`}
            />
            <button
              onClick={handleSearchClick}
              disabled={!destination}
              className="sm:absolute sm:right-2 sm:top-1/2 sm:-translate-y-1/2 bg-[#E53E3E] hover:bg-red-600 text-white px-6 py-2 rounded-full flex items-center justify-center gap-2 transition-colors w-full sm:w-auto"
            >
              <Search size={20} />
              <span>Search</span>
              <ChevronRight size={20} />
            </button>
            {validationError && (
              <div className="absolute -bottom-8 left-0 text-red-500 text-sm">
                {validationError}
              </div>
            )}
            
            {/* Predictions Dropdown */}
            {showPredictions && (destination.length > 0 || loading) && (
              <div className="absolute w-full mt-2 sm:mt-2 bg-white rounded-3xl shadow-lg overflow-hidden z-50 border border-gray-100">
                {loading ? (
                  <div className="p-4 text-center text-gray-500">
                    <div className="inline-block animate-spin rounded-full h-6 w-6 border-2 border-[#E53E3E] border-t-transparent"></div>
                  </div>
                ) : predictions.length > 0 ? (
                  <ul className="max-h-64 overflow-y-auto">
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handlePredictionClick(prediction)}
                        className="px-6 py-3 hover:bg-gray-50 cursor-pointer transition-colors"
                      >
                        <div className="font-medium text-gray-800">
                          {prediction.structured_formatting.main_text}
                        </div>
                        <div className="text-sm text-gray-500">
                          {prediction.structured_formatting.secondary_text}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="p-4 text-center text-gray-500">
                    No cities found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <MultiDestinationModal
        isOpen={showMultiDestModal}
        onClose={() => setShowMultiDestModal(false)}
        primaryDestination={destination}
        onContinue={handleMultiDestContinue}
      />
      
      <TravelPreferencesModal
        isOpen={showModal}
        onClose={handleTravelPreferencesClose}
        destinations={selectedDestinations}
        setShowMultiDestModal={setShowMultiDestModal}
        setShowAuthModal={setShowAuthModal}
      />
    </div>
  );
}