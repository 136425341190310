import React, { useEffect, useRef } from 'react';
import { X, Crown, ArrowRight, Users, MessageSquare, Calendar, Star, Briefcase } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../hooks/useAuthState';

interface ConsultantUpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
}

export default function ConsultantUpgradeModal({
  isOpen,
  onClose,
  setShowAuthModal
}: ConsultantUpgradeModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { user } = useAuthState();

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
      initialFocusRef.current?.focus();
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  const handleGoToItineraries = () => {
    if (user) {
      navigate('/my-itineraries');
      onClose();
    } else {
      // Store return URL
      sessionStorage.setItem('returnUrl', '/my-itineraries');
      setShowAuthModal('signup');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
      role="dialog"
      aria-modal="true"
      aria-labelledby="consultant-modal-title"
    >
      <div
        ref={modalRef}
        className="bg-white rounded-3xl w-full max-w-lg p-8 relative animate-slide-up"
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>

        <div className="text-center mb-8">
          <div className="w-16 h-16 bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full flex items-center justify-center mx-auto mb-4">
            <Briefcase className="text-white" size={32} />
          </div>
          <h2 
            id="consultant-modal-title"
            className="text-2xl font-bold text-[#2C3E50] mb-4"
          >
            Consultant Services
          </h2>
          <p className="text-gray-600 mb-6">
            Get personalized travel consulting from our expert team, starting at $49.95/hour.
          </p>

          <div className="bg-gradient-to-r from-indigo-50 to-purple-50 rounded-2xl p-6 mb-6 text-left">
            <h3 className="font-semibold text-[#2C3E50] mb-4 flex items-center gap-2">
              <Crown className="text-indigo-600" size={20} />
              Enterprise-Level Features:
            </h3>
            <ul className="space-y-4">
              <li className="flex items-center gap-3">
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Users size={18} className="text-indigo-600" />
                </div>
                <div>
                  <h4 className="font-medium text-[#2C3E50]">Dedicated Travel Consultant</h4>
                  <p className="text-sm text-gray-600">Personal expert assigned to your account</p>
                </div>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <MessageSquare size={18} className="text-indigo-600" />
                </div>
                <div>
                  <h4 className="font-medium text-[#2C3E50]">24/7 WhatsApp Support</h4>
                  <p className="text-sm text-gray-600">Direct line to your travel expert</p>
                </div>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Calendar size={18} className="text-indigo-600" />
                </div>
                <div>
                  <h4 className="font-medium text-[#2C3E50]">Real-Time Plan Adjustments</h4>
                  <p className="text-sm text-gray-600">Instant itinerary modifications as needed</p>
                </div>
              </li>
              <li className="flex items-center gap-3">
                <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center flex-shrink-0">
                  <Star size={18} className="text-indigo-600" />
                </div>
                <div>
                  <h4 className="font-medium text-[#2C3E50]">VIP Access</h4>
                  <p className="text-sm text-gray-600">Exclusive events and premium experiences</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            ref={initialFocusRef}
            onClick={handleGoToItineraries}
            className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-full hover:from-indigo-700 hover:to-purple-700 transition-all flex items-center gap-2 shadow-lg"
          >
            Take me to My Itineraries
            <ArrowRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}