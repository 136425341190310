import React, { useState, useEffect, useRef } from 'react';
import { X, Crown, Star, Check, Send } from 'lucide-react';
import { saveContactSubmission } from '../lib/firebase';
import { useAuthState } from '../hooks/useAuthState';
import { useUserName } from '../hooks/useUserName';

interface PremiumUpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  itineraryId?: string | null;
}

export default function PremiumUpgradeModal({
  isOpen,
  onClose,
  itineraryId
}: PremiumUpgradeModalProps) {
  const { user } = useAuthState();
  const { name: userName } = useUserName(user?.uid);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [honeypot, setHoneypot] = useState('');
  const [formToken, setFormToken] = useState('');
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Generate CSRF token on component mount
    setFormToken(Math.random().toString(36).substring(2));
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormData(prev => ({
        ...prev,
        email: user?.email || '',
        name: userName || ''
      }));
    }
  }, [isOpen, user, userName]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    // Name validation (letters only, min 2 chars)
    if (!formData.name.match(/^[A-Za-z\s]{2,}$/)) {
      newErrors.name = 'Name must contain only letters and be at least 2 characters long';
    }
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Message validation (min 10 chars)
    if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    if (honeypot) return; // If honeypot is filled, silently reject
    
    setIsSubmitting(true);
    
    try {
      await saveContactSubmission({
        ...formData,
        itineraryId,
        type: 'premium_upgrade'
      });
      
      setShowSuccess(true);
      setTimeout(() => {
        onClose();
        setFormData({ name: '', email: '', message: '' });
        setShowSuccess(false);
      }, 2000);
      
    } catch (error) {
      setErrors({
        ...errors,
        submit: 'Failed to send request. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 overflow-y-auto">
      <div className="min-h-screen px-4 text-center">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div 
          ref={modalRef}
          className="inline-block w-full max-w-md p-4 sm:p-6 md:p-8 my-8 text-left align-middle bg-white rounded-lg shadow-xl transform animate-slide-up relative"
        >
          <button
            onClick={onClose}
            className="absolute right-3 sm:right-4 top-3 sm:top-4 text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X size={20} />
          </button>

          <div className="text-center mb-4 sm:mb-6">
            <div className="w-12 h-12 sm:w-16 sm:h-16 bg-[#E53E3E]/10 rounded-full flex items-center justify-center mx-auto mb-3 sm:mb-4">
              <Crown className="text-[#E53E3E]" size={32} />
            </div>
            <h2 className="text-xl sm:text-2xl font-bold text-[#2C3E50] mb-2">
              Upgrade to Premium
            </h2>
            <p className="text-sm sm:text-base text-gray-600">
              Get personalized recommendations and priority support
            </p>
          </div>

          <div className="bg-gray-50 rounded-xl p-3 sm:p-4 mb-4 sm:mb-6">
            <h3 className="font-semibold text-[#2C3E50] mb-2 sm:mb-3 flex items-center gap-2 text-sm sm:text-base">
              <Star className="text-[#E53E3E]" size={20} />
              Premium Benefits
            </h3>
            <ul className="space-y-1.5 sm:space-y-2">
              <li className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <Check size={16} className="text-green-500" />
                Highly personalized recommendations
              </li>
              <li className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <Check size={16} className="text-green-500" />
                Access to hidden gems and local spots
              </li>
              <li className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <Check size={16} className="text-green-500" />
                Priority customer support
              </li>
              <li className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <Check size={16} className="text-green-500" />
                Real-time itinerary updates
              </li>
            </ul>
          </div>

          {showSuccess && (
            <div className="mb-4 sm:mb-6 p-3 sm:p-4 bg-green-50 text-green-600 rounded-3xl text-center text-sm sm:text-base">
              Premium upgrade request sent successfully!
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
            <div>
              <label htmlFor="name" className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, name: e.target.value }));
                  if (errors.name) setErrors(prev => ({ ...prev, name: '' }));
                }}
                className={`w-full px-3 sm:px-4 py-1.5 sm:py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent text-sm sm:text-base ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                }`}
                required
              />
              {errors.name && (
                <p className="mt-1 text-xs sm:text-sm text-red-600">{errors.name}</p>
              )}
            </div>

            <div>
              <label htmlFor="email" className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, email: e.target.value }));
                  if (errors.email) setErrors(prev => ({ ...prev, email: '' }));
                }}
                className={`w-full px-3 sm:px-4 py-1.5 sm:py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent text-sm sm:text-base ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                required
              />
              {errors.email && (
                <p className="mt-1 text-xs sm:text-sm text-red-600">{errors.email}</p>
              )}
            </div>

            {/* Honeypot field - hidden from users */}
            <div className="hidden">
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                name="website"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                tabIndex={-1}
                autoComplete="off"
              />
            </div>

            {/* Hidden CSRF token */}
            <input type="hidden" name="_csrf" value={formToken} />

            <div>
              <label htmlFor="message" className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">
                Special Requirements
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, message: e.target.value }));
                  if (errors.message) setErrors(prev => ({ ...prev, message: '' }));
                }}
                rows={4}
                className={`w-full px-3 sm:px-4 py-1.5 sm:py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent resize-none text-sm sm:text-base ${
                  errors.message ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="Tell us about any specific preferences or requirements..."
                required
              />
              {errors.message && (
                <p className="mt-1 text-xs sm:text-sm text-red-600">{errors.message}</p>
              )}
            </div>

            {errors.submit && (
              <div className="p-2 sm:p-3 bg-red-50 text-red-600 rounded-3xl text-xs sm:text-sm">
                {errors.submit}
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-[#E53E3E] text-white py-1.5 sm:py-2 px-3 sm:px-4 rounded-3xl hover:bg-red-600 transition-colors flex items-center justify-center gap-2 text-sm sm:text-base font-medium"
            >
              <Send size={20} />
              {isSubmitting ? 'Sending...' : 'Request Premium Upgrade'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}