import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import PremiumUpgradeModal from '../components/PremiumUpgradeModal';
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus';
import { cancelSubscription } from '../lib/stripe';
import { 
  User as UserIcon, 
  Mail,
  Check,
  CheckCircle2,
  Infinity,
  Crown,
  MessageSquare,
  Key, 
  X, 
  Calendar, 
  MapPin, 
  Clock,
  ArrowUpDown,
  Loader2
} from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { useUserName } from '../hooks/useUserName';
import ItineraryDetailsModal from '../components/ItineraryDetailsModal';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs,
  limit,
  startAfter,
  DocumentData,
  QueryDocumentSnapshot,
  CollectionReference
} from 'firebase/firestore';
import { 
  updatePassword, 
  EmailAuthProvider, 
  reauthenticateWithCredential 
} from 'firebase/auth';

// Scroll restoration function
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant' // Use 'instant' instead of 'smooth' for immediate scrolling
  });
};

interface Itinerary {
  id: string;
  title: string;
  destination: string;
  createdAt: Date;
  startDate: Date;
  endDate: Date;
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
}

export default function MyItineraries({ 
  showContactForm, 
  setShowContactForm, 
  setSelectedItinerary,
  setShowAuthModal
}: MyItinerariesProps) {
  const { name, loading: nameLoading, error: nameError } = useUserName(auth.currentUser?.uid);
  const navigate = useNavigate();

  // User Profile State
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);

  // Itineraries State
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortField, setSortField] = useState<'createdAt' | 'title'>('createdAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedItineraryId, setSelectedItineraryId] = useState<string | null>(null);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [selectedPremiumItinerary, setSelectedPremiumItinerary] = useState<string | null>(null);
  const [cancelingSubscription, setCancelingSubscription] = useState(false);
  const { 
    status: subscriptionStatus, 
    subscriptionEndDate,
    loading: subscriptionLoading 
  } = useSubscriptionStatus(auth.currentUser?.uid);

  const handleCancelSubscription = async () => {
    if (!auth.currentUser) return;
    
    if (!window.confirm('Are you sure you want to cancel your subscription? You will lose access to unlimited features at the end of your billing period.')) {
      return;
    }
    
    setCancelingSubscription(true);
    try {
      await cancelSubscription(auth.currentUser.uid);
      // Success message will be shown automatically due to subscription status change
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      alert('Failed to cancel subscription. Please try again or contact support.');
    } finally {
      setCancelingSubscription(false);
    }
  };

  // Scroll to top when component mounts
  useEffect(() => {
    scrollToTop();
  }, []);

  const ITEMS_PER_PAGE = 10;

  // Password Change Handler
  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError(null);
    setPasswordSuccess(false);
    setChangingPassword(true);

    try {
      if (newPassword !== confirmPassword) {
        throw new Error('New passwords do not match');
      }

      const user = auth.currentUser;
      if (!user || !user.email) throw new Error('No user logged in');

      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      setPasswordSuccess(true);
      setTimeout(() => {
        setShowPasswordModal(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }, 2000);
    } catch (err) {
      setPasswordError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setChangingPassword(false);
    }
  };

  // Fetch Itineraries
  const fetchItineraries = async (isInitial = false) => {
    try {
      if (!auth.currentUser) return;

      const itinerariesRef = collection(db, `users/${auth.currentUser.uid}/itineraries`) as CollectionReference<DocumentData>;
      let q = query(
        itinerariesRef,
        orderBy(sortField, sortDirection),
        limit(ITEMS_PER_PAGE)
      );

      if (!isInitial && lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const snapshot = await getDocs(q);
      const newItineraries = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        startDate: doc.data().startDate.toDate(),
        endDate: doc.data().endDate.toDate()
      })) as Itinerary[];

      setLastDoc(snapshot.docs[snapshot.docs.length - 1] || null);
      setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);

      if (isInitial) {
        setItineraries(newItineraries);
      } else {
        setItineraries(prev => [...prev, ...newItineraries]);
      }
    } catch (err) {
      setError('Failed to load itineraries');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Initial Load
  useEffect(() => {
    fetchItineraries(true);
  }, [sortField, sortDirection]);

  const handleSort = (field: 'createdAt' | 'title') => {
    if (field === sortField) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* User Profile Section */}
      <div className="bg-white rounded-3xl shadow-lg p-8 mb-8">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 bg-[#4A90E2]/10 rounded-full flex items-center justify-center">
              <UserIcon size={32} className="text-[#4A90E2]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-[#2C3E50]">
                {nameLoading ? (
                  <div className="h-8 w-32 bg-gray-200 animate-pulse rounded"></div>
                ) : nameError ? (
                  <span className="text-red-500">Error loading name</span>
                ) : name || 'User'}
              </h2>
              <div className="flex items-center gap-2 text-gray-600">
                <Mail size={16} />
                {auth.currentUser?.email}
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowPasswordModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors"
          >
            <Key size={20} />
            Change Password
          </button>
        </div>
      </div>

      {/* Unlimited Plan CTA */}
      {subscriptionLoading ? (
        <div className="bg-white rounded-3xl shadow-lg p-8 mb-8">
          <div className="flex justify-center">
            <Loader2 className="w-8 h-8 text-[#E53E3E] animate-spin" />
          </div>
        </div>
      ) : subscriptionStatus === 'active' ? (
        <div className="bg-gradient-to-r from-green-600 to-green-700 rounded-3xl shadow-lg p-8 mb-8 text-white">
          <div className="flex flex-col md:flex-row items-center justify-between gap-6">
            <div className="flex-1">
              <div className="flex items-center gap-2 mb-2">
                <CheckCircle2 size={24} />
                <h3 className="text-2xl font-bold">Active Unlimited Subscription</h3>
              </div>
              <p className="text-white/90 mb-4">
                You have unlimited access to all premium features. Create as many AI-powered itineraries as you want!
              </p>
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={handleCancelSubscription}
                disabled={cancelingSubscription}
                className="px-8 py-3 bg-white text-green-600 rounded-full hover:bg-gray-100 transition-colors font-semibold text-lg flex items-center gap-2 shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {cancelingSubscription ? (
                  <>
                    <Loader2 size={24} className="animate-spin" />
                    Canceling...
                  </>
                ) : (
                  <>
                    <X size={24} />
                    Cancel Subscription
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : subscriptionStatus === 'canceling' ? (
        <div className="bg-gradient-to-r from-amber-500 to-amber-600 rounded-3xl shadow-lg p-8 mb-8 text-white">
          <div className="flex flex-col md:flex-row items-center justify-between gap-6">
            <div className="flex-1">
              <div className="flex items-center gap-2 mb-2">
                <Calendar size={24} />
                <h3 className="text-2xl font-bold">Subscription Ending Soon</h3>
              </div>
              <p className="text-white/90 mb-2">
                Your unlimited access will continue until the end of your billing period on <span className="font-bold underline">{format(new Date(subscriptionEndDate), 'MMMM d, yyyy')}</span>.
              </p>
              <p className="text-white/90">
                After this date, you'll return to the basic plan. Resubscribe anytime to regain unlimited access.
              </p>
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={() => navigate('/checkout')}
                className="px-8 py-3 bg-white text-amber-600 rounded-full hover:bg-gray-100 transition-colors font-semibold text-lg flex items-center gap-2 shadow-lg"
              >
                <Infinity size={24} />
                Resubscribe Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gradient-to-r from-[#E53E3E] to-red-700 rounded-3xl shadow-lg p-8 mb-8 text-white">
          <div className="flex flex-col md:flex-row items-center justify-between gap-6">
            <div className="flex-1">
              <h3 className="text-2xl font-bold mb-2">Upgrade to Unlimited Plan</h3>
              <p className="text-white/90 mb-4">
                Create unlimited AI-powered itineraries for just $49.95/year. Save time and money while planning your adventures!
              </p>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm">
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-white" />
                  Unlimited AI itineraries
                </li>
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-white" />
                  Priority queue processing
                </li>
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-white" />
                  Advanced customization
                </li>
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-white" />
                  Premium travel guides
                </li>
              </ul>
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={() => navigate('/checkout')}
                className="px-8 py-3 bg-white text-[#E53E3E] rounded-full hover:bg-gray-100 transition-colors font-semibold text-lg flex items-center gap-2 shadow-lg"
              >
                <Infinity size={24} />
                Get the Unlimited Plan
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Itineraries Section */}
      <div className="bg-white rounded-3xl shadow-lg p-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-bold text-[#2C3E50]">My Itineraries</h3>
          <div className="flex gap-4">
            <button
              onClick={() => handleSort('title')}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors ${
                sortField === 'title' ? 'bg-[#4A90E2] text-white' : 'bg-red-100 hover:bg-gray-200 text-gray-700'
              }`}
            >
              <ArrowUpDown size={16} />
              Title
            </button>
            <button
              onClick={() => handleSort('createdAt')}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors ${
                sortField === 'createdAt' ? 'bg-[#e24a4a] text-white' : 'bg-red-100 hover:bg-gray-200 text-gray-700'
              }`}
            >
              <Clock size={16} />
              Date
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#4A90E2]"></div>
          </div>
        ) : error ? (
          <div className="text-center py-8">
            <p className="text-red-600 mb-4">{error}</p>
            <button
              onClick={() => fetchItineraries(true)}
              className="px-4 py-2 bg-[#4A90E2] text-white rounded-full hover:bg-blue-600 transition-colors"
            >
              Try Again
            </button>
          </div>
        ) : itineraries.length === 0 ? (
          <div className="text-center py-8 text-gray-600">
            No itineraries found. Start planning your first trip!
          </div>
        ) : (
          <>
            <div className="space-y-4">
              {itineraries.map((itinerary) => (
                <div
                  key={itinerary.id}
                  className="border border-gray-200 rounded-2xl p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                    <div>
                      <h4 className="text-xl font-semibold text-[#2C3E50] mb-2">
                        {itinerary.title}
                      </h4>
                      <div className="flex flex-wrap gap-4 text-gray-600">
                        <div className="flex items-center gap-2">
                          <MapPin size={16} />
                          {itinerary.destination}
                        </div>
                        <div className="flex items-center gap-2">
                          <Calendar size={16} />
                          <span className="text-[#E53E3E]">
                            {format(itinerary.startDate, 'MMM d')} - {format(itinerary.endDate, 'MMM d, yyyy')}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <Clock size={16} />
                          Created {format(itinerary.createdAt, 'MMM d, yyyy')}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-3">
                      <button
                        onClick={() => {
                          setSelectedPremiumItinerary(itinerary.id);
                          setShowPremiumModal(true);
                        }}
                       className="px-4 py-2 bg-gradient-to-r from-indigo-800 to-indigo-900 text-white rounded-full hover:from-indigo-900 hover:to-indigo-950 transition-colors flex items-center justify-center gap-2 group"
                        aria-label="Upgrade to Premium"
                      >
                        <Crown 
                          size={18}
                          className="transform group-hover:scale-110 transition-transform"
                        />
                        <span className="whitespace-nowrap">Make Premium</span>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedItinerary(itinerary.id);
                          setShowContactForm(true);
                        }}
                       className="px-4 py-2 bg-gradient-to-r from-sky-400 to-sky-500 text-white rounded-full hover:from-sky-500 hover:to-sky-600 transition-colors flex items-center justify-center gap-2 group"
                        aria-label="Request Consultancy"
                      >
                        <MessageSquare 
                          size={18}
                          className="transform group-hover:scale-110 transition-transform"
                        />
                        <span className="whitespace-nowrap">Ask for a Consultancy</span>
                      </button>
                      <button 
                        className="px-6 py-2 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
                        aria-label="View Itinerary Details"
                        onClick={() => setSelectedItineraryId(itinerary.id)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {hasMore && (
              <div className="text-center mt-8">
                <button
                  onClick={() => fetchItineraries(false)}
                  className="px-6 py-2 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors inline-flex items-center gap-2"
                >
                  Load More
                  <Loader2 size={16} className="animate-spin" />
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* Password Change Modal */}
      {showPasswordModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-md p-6 relative animate-slide-up">
            <button
              onClick={() => setShowPasswordModal(false)}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <X size={20} />
            </button>

            <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
              Change Password
            </h2>

            {passwordError && (
              <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-3xl text-sm">
                {passwordError}
              </div>
            )}

            {passwordSuccess && (
              <div className="mb-4 p-3 bg-green-50 text-green-600 rounded-3xl text-sm">
                Password updated successfully!
              </div>
            )}

            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#4A90E2] focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#4A90E2] focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#4A90E2] focus:border-transparent"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={changingPassword}
                className="w-full bg-[#4A90E2] text-white py-2 px-4 rounded-3xl hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {changingPassword ? (
                  <>
                    <Loader2 size={20} className="animate-spin" />
                    Updating...
                  </>
                ) : (
                  'Update Password'
                )}
              </button>
            </form>
          </div>
        </div>
      )}
      
      <PremiumUpgradeModal
        isOpen={showPremiumModal}
        onClose={() => {
          setShowPremiumModal(false);
          setSelectedPremiumItinerary(null);
        }}
        itineraryId={selectedPremiumItinerary}
      />
      
      <ItineraryDetailsModal
        isOpen={selectedItineraryId !== null}
        onClose={() => setSelectedItineraryId(null)}
        itineraryId={selectedItineraryId}
      />
    </div>
  );
}