import React, { useEffect, useRef, useState } from 'react';
import { X, Share2, Download, Calendar, MapPin, Clock, CheckCircle2, Lock, Infinity } from 'lucide-react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus';
import { auth } from '../lib/firebase';

interface ItineraryConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  itineraryData: {
    destination: string;
    startDate: Date;
    endDate: Date;
    dailyActivities: Record<string, Array<{
      time: string;
      activity: string;
      location: string;
      notes?: string;
    }>>;
  } | null;
}

export default function ItineraryConfirmationModal({
  isOpen,
  onClose,
  itineraryData
}: ItineraryConfirmationModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { status: subscriptionStatus } = useSubscriptionStatus(auth.currentUser?.uid);
  const [firstDayKey, setFirstDayKey] = useState<string | null>(null);

  useEffect(() => {
    if (itineraryData?.dailyActivities) {
      const sortedDates = Object.keys(itineraryData.dailyActivities).sort();
      setFirstDayKey(sortedDates[0] || null);
    }
  }, [itineraryData]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen || !itineraryData) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div 
        ref={modalRef}
        className="bg-white rounded-3xl w-full max-w-4xl relative animate-slide-up overflow-hidden"
      >
        {/* Success Banner */}
        <div className="bg-green-50 p-4 flex items-center justify-center gap-2 text-green-600">
          <CheckCircle2 size={20} />
          <span>Itinerary created successfully!</span>
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 z-10"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>

        {/* Header */}
        <div className="bg-[#E53E3E] text-white p-8">
          <h2 className="text-3xl font-bold mb-4">{itineraryData.destination}</h2>
          <div className="flex flex-wrap gap-4 text-white/90">
            <div className="flex items-center gap-2">
              <Calendar size={20} />
              <span>
                {format(itineraryData.startDate, 'MMM d')} - {format(itineraryData.endDate, 'MMM d, yyyy')}
              </span>
            </div>
          </div>
        </div>

        {/* Content */}
        {firstDayKey && (
          <div
            ref={contentRef}
            className="max-h-[calc(100vh-16rem)] overflow-y-auto divide-y divide-gray-100"
          >
            {/* First Day's Activities (Always Shown) */}
            <div key={firstDayKey} className="p-8 space-y-6">
              <div className="bg-gray-50 rounded-2xl p-6">
                <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                  {format(new Date(firstDayKey), 'EEEE, MMMM d')}
                </h4>
                <div className="space-y-4">
                  {itineraryData.dailyActivities[firstDayKey].map((activity, index) => (
                    <div 
                      key={index}
                      className="flex items-start gap-4 p-4 bg-white rounded-xl"
                    >
                      <div className="flex-shrink-0 w-16 text-sm font-medium text-gray-600">
                        {activity.time}
                      </div>
                      <div>
                        <h5 className="font-medium text-[#2C3E50] mb-1">
                          {activity.activity}
                        </h5>
                        <p className="text-sm text-gray-600 mb-2">
                          {activity.location}
                        </p>
                        {activity.notes && (
                          <p className="text-sm text-gray-500 italic">
                            {activity.notes}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Remaining Days (Premium Only) */}
            {(subscriptionStatus === 'active' || subscriptionStatus === 'canceling') ? (
              Object.entries(itineraryData.dailyActivities)
                .filter(([date]) => date !== firstDayKey)
                .map(([date, activities]) => (
                  <div key={date} className="p-8 space-y-6">
                    <div className="bg-gray-50 rounded-2xl p-6">
                      <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                        {format(new Date(date), 'EEEE, MMMM d')}
                      </h4>
                      <div className="space-y-4">
                        {activities.map((activity, index) => (
                          <div 
                            key={index}
                            className="flex items-start gap-4 p-4 bg-white rounded-xl"
                          >
                            <div className="flex-shrink-0 w-16 text-sm font-medium text-gray-600">
                              {activity.time}
                            </div>
                            <div>
                              <h5 className="font-medium text-[#2C3E50] mb-1">
                                {activity.activity}
                              </h5>
                              <p className="text-sm text-gray-600 mb-2">
                                {activity.location}
                              </p>
                              {activity.notes && (
                                <p className="text-sm text-gray-500 italic">
                                  {activity.notes}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="bg-gradient-to-r from-[#E53E3E]/5 to-red-700/5 rounded-3xl p-8 text-center mx-8 mb-8">
                <div className="w-16 h-16 bg-[#E53E3E]/10 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Lock className="text-[#E53E3E]" size={24} />
                </div>
                <h4 className="text-xl font-semibold text-[#2C3E50] mb-2">
                  Unlock Full Itinerary
                </h4>
                <p className="text-gray-600 mb-6 max-w-md mx-auto">
                  Get access to your complete travel schedule, including all destinations and activities, with our Unlimited Plan.
                </p>
                <button
                  onClick={() => {
                    onClose();
                    navigate('/checkout');
                  }}
                  className="px-6 py-3 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors inline-flex items-center gap-2"
                >
                  <Infinity size={20} />
                  Upgrade to Unlimited
                </button>
              </div>
            )}
          </div>
        )}

        {/* Footer */}
        <div className="p-6 bg-gray-50 border-t border-gray-100 flex justify-end">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}