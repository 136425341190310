import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { PerplexityService } from '../services/ai/perplexity';

export function useItineraryGeneration() {
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateItinerary = async (itineraryId: string, preferences: any) => {
    if (!auth.currentUser) {
      throw new Error('User not authenticated');
    }

    setGenerating(true);
    setError(null);

    try {
      const perplexity = new PerplexityService();

      // Extract relevant preferences for AI generation
      const startDate =
        preferences.startDate instanceof Date
          ? preferences.startDate
          : preferences.startDate.toDate();
      const endDate =
        preferences.endDate instanceof Date
          ? preferences.endDate
          : preferences.endDate.toDate();

      // Validate input data
      if (!startDate || !endDate) {
        throw new Error('Start and end dates are required');
      }

      if (!preferences.activities || !Array.isArray(preferences.activities)) {
        throw new Error('Activities must be provided as an array');
      }

      if (!preferences.transport) {
        throw new Error('Transportation preference is required');
      }

      if (!preferences.budget) {
        throw new Error('Budget selection is required');
      }

      if (!preferences.partySize || typeof preferences.partySize !== 'object') {
        throw new Error('Party size information is required');
      }

      const input = {
        destinations: [
          preferences.destination,
          preferences.destination2,
          preferences.destination3,
          preferences.destination4,
        ].filter(Boolean),
        startDate: startDate,
        endDate: endDate,
        activities: preferences.activities,
        transport: preferences.transport,
        firstVisit: preferences.firstVisit,
        budget: preferences.budget,
        partySize: preferences.partySize,
        needsAccommodation: preferences.needsAccommodation,
        needsFlight: preferences.needsFlight,
        originCity: preferences.originCity,
        accommodationType: preferences.accommodationType,
      };

      // Generate itinerary using Perplexity
      const generatedData = await perplexity.generateItinerary(input);

      if (!generatedData.dailyActivities || Object.keys(generatedData.dailyActivities).length === 0) {
        throw new Error('Failed to generate itinerary activities');
      }

      // Save the generated itinerary
      const itineraryRef = doc(
        db,
        `users/${auth.currentUser.uid}/itineraries/${itineraryId}`
      );
      await updateDoc(itineraryRef, {
        ...generatedData,
        status: 'generated',
        generatedAt: new Date(),
      });

      return generatedData;
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'An unexpected error occurred while generating your itinerary. Please try again.';

      setError(errorMessage);

      // Update the itinerary status to error
      try {
        const itineraryRef = doc(
          db,
          `users/${auth.currentUser.uid}/itineraries/${itineraryId}`
        );
        await updateDoc(itineraryRef, {
          status: 'error',
          error: errorMessage,
          updatedAt: new Date(),
        });
      } catch (updateError) {
        console.error('Failed to update itinerary error status:', updateError);
      }

      throw err;
    } finally {
      setGenerating(false);
    }
  };

  return {
    generating,
    error,
    generateItinerary,
  };
}