import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function useUserName(userId: string | undefined) {
  const [name, setName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const userRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          setName(doc.data()?.name || null);
        } else {
          setName(null);
        }
        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error('Error fetching user name:', err);
        setError('Failed to load user name');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  return { name, loading, error };
}