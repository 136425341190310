import React, { useState, useEffect } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { format } from 'date-fns';
import { db, auth } from '../lib/firebase';
import { doc, setDoc, updateDoc, collection } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePlacesAutocomplete } from '../hooks/usePlacesAutocomplete';
import { useItineraryGeneration } from '../hooks/useItineraryGeneration';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ItineraryConfirmationModal from './ItineraryConfirmationModal';

interface TravelPreferencesModalProps {
  isOpen: boolean;
  onClose: () => void;
  destinations: string[];
  setShowMultiDestModal: (value: boolean) => void;
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
}

interface AuthState {
  user: any;
  loading: boolean;
}

type TravelParty =
  | 'solo'
  | 'couple'
  | 'family'
  | 'family-with-children'
  | 'friends';
type TransportPreference = 'public' | 'taxi' | 'rental';
type AccommodationType = 'hotels' | 'apartments' | 'bb' | 'hostels';

interface PartySize {
  adults: number;
  children: number;
}

const defaultPartySizes: Record<TravelParty, PartySize> = {
  solo: { adults: 1, children: 0 },
  couple: { adults: 2, children: 0 },
  family: { adults: 4, children: 0 },
  'family-with-children': { adults: 2, children: 2 },
  friends: { adults: 4, children: 0 },
};

const activities = [
  { id: 'art-museums', label: 'Art Museums' },
  { id: 'fine-dining', label: 'Fine Dining' },
  { id: 'romantic-walks', label: 'Romantic Walks' },
  { id: 'live-music', label: 'Live Music' },
  { id: 'shopping', label: 'Shopping' },
  { id: 'nightlife', label: 'Nightlife' },
  { id: 'street-art', label: 'Street Art' },
  { id: 'city-tours', label: 'City Tours' },
  { id: 'historical-landmarks', label: 'Historical Landmarks' },
];

export default function TravelPreferencesModal({
  isOpen,
  onClose,
  destinations,
  setShowMultiDestModal,
  setShowAuthModal,
}: TravelPreferencesModalProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [authChecked, setAuthChecked] = useState(false);
  const [step, setStep] = useState(1);
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    loading: true,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthState({ user, loading: false });
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (authChecked && !authState.loading && !authState.user && isOpen) {
      // Store current location and modal state
      const returnUrl = `${location.pathname}${location.search}${location.hash}`;
      sessionStorage.setItem('returnUrl', returnUrl);
      sessionStorage.setItem('showTravelModal', 'true');
      sessionStorage.setItem('selectedCity', destinations[0]);

      // Close modal and show auth modal
      onClose();
      setShowAuthModal('signin');
    }
  }, [
    authChecked,
    authState.loading,
    authState.user,
    isOpen,
    location,
    onClose,
    setShowAuthModal,
    destinations,
  ]);

  // Update formData when destinations changes
  useEffect(() => {
    if (destinations[0]) {
      setFormData((prev) => ({
        ...prev,
        destination: destinations[0],
      }));
    }
  }, [destinations]);

  const [formData, setFormData] = useState({
    destination: '',
    startDate: null as Date | null,
    endDate: null as Date | null,
    needsFlight: null as boolean | null,
    originCity: '',
    travelParty: '' as TravelParty,
    firstVisit: null as boolean | null,
    transport: '' as TransportPreference,
    activities: [] as string[],
    needsAccommodation: null as boolean | null,
    accommodationType: '' as AccommodationType,
    partySize: { adults: 1, children: 0 },
    budget: 'under-50',
  });

  const {
    predictions,
    loading: originCityLoading,
    getPlacePredictions,
  } = usePlacesAutocomplete();
  const [showOriginPredictions, setShowOriginPredictions] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    generateItinerary,
    generating,
    error: generationError,
  } = useItineraryGeneration();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [generatedItinerary, setGeneratedItinerary] = useState(null);

  // Store the previous step to handle back navigation
  const [previousStep, setPreviousStep] = useState<number | null>(null);

  const totalSteps = formData.needsAccommodation ? 10 : 9;

  const handleNext = async () => {
    if (
      step === totalSteps ||
      (step === (formData.needsAccommodation ? 9 : 8) &&
        !formData.needsAccommodation)
    ) {
      await savePreferences();
    } else {
      setStep((s) => s + 1);
    }
  };

  const handleBack = () => {
    if (step === 1) {
      // Return to multi-destination selection
      onClose();
    } else {
      setStep((s) => s - 1);
    }
  };

  // Reset step when modal opens
  useEffect(() => {
    if (isOpen) {
      setStep(1);
    }
  }, [isOpen]);

  const handleDateSelect = (dates: Date | Date[]) => {
    if (Array.isArray(dates)) {
      setFormData((prev) => ({
        ...prev,
        startDate: dates[0],
        endDate: dates[1],
      }));
    }
  };

  const handlePartyTypeSelect = (type: TravelParty) => {
    setFormData((prev) => ({
      ...prev,
      travelParty: type,
      partySize: defaultPartySizes[type],
    }));
  };

  const handleActivityToggle = (activityId: string) => {
    setFormData((prev) => ({
      ...prev,
      activities: prev.activities.includes(activityId)
        ? prev.activities.filter((id) => id !== activityId)
        : [...prev.activities, activityId],
    }));
  };

  const savePreferences = async () => {
    if (!authState.user) {
      const returnUrl = `${location.pathname}${location.search}${location.hash}`;
      sessionStorage.setItem('returnUrl', returnUrl);
      sessionStorage.setItem('showTravelModal', 'true');
      sessionStorage.setItem('selectedCity', destinations[0]);

      onClose();
      setShowAuthModal('signin');
      return;
    }

    setSaving(true);
    try {
      const itinerariesRef = collection(
        db,
        `users/${authState.user.uid}/itineraries`
      );
      const newItineraryRef = doc(itinerariesRef);
      const itineraryId = newItineraryRef.id;
      const startDate = formData.startDate;
      const endDate = formData.endDate;

      // Calculate number of days
      const days = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

      // Create initial itinerary document
      const destinationFields = {
        destination: destinations[0],
        ...(destinations[1] && { destination2: destinations[1] }),
        ...(destinations[2] && { destination3: destinations[2] }),
        ...(destinations[3] && { destination4: destinations[3] }),
      };

      // Save initial itinerary data
      await setDoc(newItineraryRef, {
        ...formData,
        ...destinationFields,
        title: `${days} Day Trip To ${destinations[0]}`,
        startDate,
        endDate,
        createdAt: new Date(),
        status: 'draft',
      });

      try {
        const generatedData = await generateItinerary(itineraryId, {
          ...formData,
          ...destinationFields,
        });
        console.log('Itinerary generated successfully:', generatedData);

        await updateDoc(newItineraryRef, {
          dailyActivities: generatedData,
          status: 'generated',
          generatedAt: new Date(),
        });

        setGeneratedItinerary({
          destination: destinations[0],
          startDate,
          endDate,
          dailyActivities: generatedData,
        });

        console.log('Setting show confirmation to true');
        onClose();
        setShowMultiDestModal(false);
        navigate('/my-itineraries');
        setShowConfirmation(true);
      } catch (error) {
        console.error('Failed to generate itinerary:', error);

        // Show error message to user
        const errorMessage =
          error instanceof Error
            ? error.message
            : 'Failed to generate itinerary. Please try again.';

        alert(errorMessage);

        // Close modals
        onClose();
        setShowMultiDestModal(false);
        setShowConfirmation(false);

        // Navigate to itineraries page to show error status
        navigate('/my-itineraries');
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
      alert('Failed to save travel preferences. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const isStepValid = () => {
    switch (step) {
      case 1:
        return (
          formData.startDate &&
          formData.endDate &&
          formData.startDate < formData.endDate
        );
      case 2:
        return formData.needsFlight ? !!formData.originCity : true;
      case 3:
        return !!formData.travelParty;
      case 4:
        return formData.firstVisit !== null;
      case 5:
        return !!formData.transport;
      case 6:
        return formData.activities.length >= 1;
      case 7:
        return formData.needsAccommodation !== null;
      case 8:
        return !formData.needsAccommodation || !!formData.accommodationType;
      case 9:
        return formData.partySize.adults > 0;
      case 10:
        return !!formData.budget;
      default:
        return false;
    }
  };

  // Don't render if not open or still checking auth
  if (!isOpen || authState.loading) return null;

  // Don't render if not authenticated
  if (!authState.user) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-3xl w-full max-w-2xl p-8 relative">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        {/* Step Content */}
        <div className="mb-8">
          {step === 1 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                When are you traveling?
              </h2>
              <Calendar
                selectRange={true}
                onChange={handleDateSelect}
                value={
                  [formData.startDate, formData.endDate].filter(Boolean) as [
                    Date,
                    Date
                  ]
                }
                minDate={new Date()}
                className="w-full rounded-lg border border-gray-200 p-4"
              />
              {formData.startDate && formData.endDate && (
                <p className="text-gray-600 text-center">
                  {format(formData.startDate, 'MMM d, yyyy')} -{' '}
                  {format(formData.endDate, 'MMM d, yyyy')}
                </p>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                Do you need flight booking recommendations?
              </h2>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, needsFlight: true }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.needsFlight
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Yes
                </button>
                <button
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      needsFlight: false,
                      originCity: '',
                    }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.needsFlight === false
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  No
                </button>
              </div>

              {formData.needsFlight && (
                <div className="mt-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Where will you be flying from?
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={formData.originCity}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          originCity: e.target.value,
                        }));
                        getPlacePredictions(e.target.value);
                        setShowOriginPredictions(true);
                      }}
                      onFocus={() => setShowOriginPredictions(true)}
                      placeholder="Enter city name"
                      className="w-full px-4 py-2 border border-gray-300 rounded-full focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                    />

                    {showOriginPredictions && formData.originCity && (
                      <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg z-10 border border-gray-100">
                        {predictions.map((prediction) => (
                          <div
                            key={prediction.place_id}
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                originCity: prediction.description,
                              }));
                              setShowOriginPredictions(false);
                            }}
                            className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                          >
                            {prediction.description}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                Who are you traveling with?
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  { id: 'solo', label: 'Solo Traveler' },
                  { id: 'couple', label: 'Couple' },
                  { id: 'family', label: 'Family' },
                  { id: 'family-with-children', label: 'Family with Children' },
                  { id: 'friends', label: 'Group of Friends' },
                ].map((option) => (
                  <button
                    key={option.id}
                    onClick={() =>
                      handlePartyTypeSelect(option.id as TravelParty)
                    }
                    className={`p-4 rounded-2xl border-2 transition-all ${
                      formData.travelParty === option.id
                        ? 'border-[#E53E3E] bg-red-50'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                Is this your first time visiting this destination?
              </h2>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, firstVisit: true }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.firstVisit === true
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Yes
                </button>
                <button
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, firstVisit: false }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.firstVisit === false
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  No
                </button>
              </div>
            </div>
          )}

          {step === 5 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                How would you like to get around?
              </h2>
              <div className="grid grid-cols-1 gap-4">
                {[
                  { id: 'public', label: 'Public Transport & Walking' },
                  { id: 'taxi', label: 'Taxi Services' },
                  { id: 'rental', label: 'Rental Car' },
                ].map((option) => (
                  <button
                    key={option.id}
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        transport: option.id as TransportPreference,
                      }))
                    }
                    className={`p-4 rounded-2xl border-2 transition-all ${
                      formData.transport === option.id
                        ? 'border-[#E53E3E] bg-red-50'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 6 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                What activities interest you?
                <span className="block text-sm font-normal text-gray-600 mt-2">
                  Please select at least one activity
                </span>
              </h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {activities.map((activity) => (
                  <button
                    key={activity.id}
                    onClick={() => handleActivityToggle(activity.id)}
                    className={`p-4 rounded-2xl border-2 transition-all ${
                      formData.activities.includes(activity.id)
                        ? 'border-[#E53E3E] bg-red-50'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    {activity.label}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === 7 && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                Do you need accommodation recommendations?
              </h2>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      needsAccommodation: true,
                    }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.needsAccommodation === true
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Yes
                </button>
                <button
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      needsAccommodation: false,
                      accommodationType: '',
                    }))
                  }
                  className={`px-6 py-3 rounded-full transition-colors ${
                    formData.needsAccommodation === false
                      ? 'bg-[#E53E3E] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  No
                </button>
              </div>
            </div>
          )}

          {step === 8 && formData.needsAccommodation && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                What type of accommodation do you prefer?
              </h2>
              <div className="grid grid-cols-2 gap-4">
                {[
                  { id: 'hotels', label: 'Hotels' },
                  { id: 'apartments', label: 'Apartments' },
                  { id: 'bb', label: 'B&Bs' },
                  { id: 'hostels', label: 'Hostels' },
                ].map((option) => (
                  <button
                    key={option.id}
                    onClick={() =>
                      setFormData((prev) => ({
                        ...prev,
                        accommodationType: option.id as AccommodationType,
                      }))
                    }
                    className={`p-4 rounded-2xl border-2 transition-all ${
                      formData.accommodationType === option.id
                        ? 'border-[#E53E3E] bg-red-50'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}

          {step === (formData.needsAccommodation ? 9 : 8) && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                How many people are traveling?
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Adults
                  </label>
                  <input
                    type="number"
                    min="1"
                    value={formData.partySize.adults}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        partySize: {
                          ...prev.partySize,
                          adults: parseInt(e.target.value) || 0,
                        },
                      }))
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-full focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Children
                  </label>
                  <input
                    type="number"
                    min="0"
                    value={formData.partySize.children}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        partySize: {
                          ...prev.partySize,
                          children: parseInt(e.target.value) || 0,
                        },
                      }))
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-full focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                  />
                </div>
              </div>
            </div>
          )}

          {step === (formData.needsAccommodation ? 10 : 9) && (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
                What's your nightly budget for accommodation?
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  { id: 'under-50', label: 'Less than $50' },
                  { id: '50-100', label: '$50-$100' },
                  { id: '100-200', label: '$100-$200' },
                  { id: '200-300', label: '$200-$300' },
                  { id: 'over-300', label: 'More than $300' },
                ].map((option) => (
                  <button
                    key={option.id}
                    onClick={() =>
                      setFormData((prev) => ({ ...prev, budget: option.id }))
                    }
                    className={`p-4 rounded-2xl border-2 transition-all ${
                      formData.budget === option.id
                        ? 'border-[#E53E3E] bg-red-50'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleBack}
            disabled={step === 1}
            className={`px-6 py-2 rounded-full flex items-center gap-2 ${
              step === 1
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ChevronLeft size={20} />
            Back
          </button>

          <button
            onClick={handleNext}
            disabled={!isStepValid() || saving}
            className={`px-6 py-2 rounded-full flex items-center gap-2 transition-colors ${
              !isStepValid() || saving
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-[#E53E3E] text-white hover:bg-red-600'
            }`}
          >
            {step === totalSteps ||
            (step === (formData.needsAccommodation ? 9 : 8) &&
              !formData.needsAccommodation) ? (
              saving ? (
                'Saving...'
              ) : (
                'Finish'
              )
            ) : (
              <>
                Next
                <ChevronRight size={20} />
              </>
            )}
          </button>
        </div>
      </div>

      <ItineraryConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        itineraryData={generatedItinerary}
      />
    </div>
  );
}
