import React from 'react';
import { Instagram, Facebook } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-gray-100 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Logo */}
        <div className="flex justify-center mb-8">
          <div className="w-10 h-10 rounded-full bg-white shadow-md flex items-center justify-center overflow-hidden border-2">
            <img 
              src="/logo.png" 
              alt="EZTravelPlan Logo" 
              className="w-10 h-10 object-contain"
            />
          </div>
        </div>

        {/* Legal Links */}
        <div className="flex justify-center space-x-6 mb-10">
          <Link
            to="/terms"
            className="text-gray-600 hover:text-[#E53E3E] transition-colors"
          >
            Terms of Use
          </Link>
          <Link
            to="/privacy"
            className="text-gray-600 hover:text-[#E53E3E] transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/data-protection"
            className="text-gray-600 hover:text-[#E53E3E] transition-colors"
          >
            Data Protection
          </Link>
        </div>

        {/* Social Media */}
        <div className="text-center mb-8">
          <h3 className="text-xl font-semibold text-[#2C3E50] mb-4">Follow Us</h3>
          <div className="flex justify-center space-x-4">
            <a
              href="https://instagram.com/eztravelplan"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-[#E53E3E] transition-colors"
              aria-label="Follow us on Instagram"
            >
              <Instagram size={32} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61573050655694"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-[#E53E3E] transition-colors"
              aria-label="Follow us on Facebook"
            >
              <Facebook size={32} />
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center text-gray-500 text-sm">
          © {currentYear} EZTRAVELPLAN. All rights reserved.
        </div>
      </div>
    </footer>
  );
}