import React, { useState, useEffect, useRef } from 'react';
import {
  X,
  Calendar,
  AlertTriangle,
  MapPin,
  Car,
  Hotel,
  UtensilsCrossed,
  Wallet,
  Loader2,
  Plane,
  ListChecks,
  Lock,
  Crown,
  Infinity,
} from 'lucide-react';
import { format } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { useSubscriptionStatus } from '../hooks/useSubscriptionStatus';
import { useNavigate } from 'react-router-dom';

interface FlightOption {
  origin: string;
  destination: string;
  airline: string;
  departureTime: string;
  arrivalTime: string;
  price: string;
  notes?: string;
}

interface AccommodationOption {
  name: string;
  type: string;
  location: string;
  priceRange: string;
  rating: string;
  amenities: string[];
  notes?: string;
}

// Helper functions for text formatting
const formatTransportType = (transport: string): string => {
  const transportTypes = {
    public: 'Public Transport & Walking',
    taxi: 'Taxi Services',
    rental: 'Rental Car',
  };
  return transportTypes[transport as keyof typeof transportTypes] || transport;
};

const formatActivityLabel = (activityId: string): string => {
  const activityLabels = {
    'art-museums': 'Art Museums',
    'fine-dining': 'Fine Dining',
    'romantic-walks': 'Romantic Walks',
    'live-music': 'Live Music',
    shopping: 'Shopping',
    nightlife: 'Nightlife',
    'street-art': 'Street Art',
    'city-tours': 'City Tours',
    'historical-landmarks': 'Historical Landmarks',
  };
  return (
    activityLabels[activityId as keyof typeof activityLabels] || activityId
  );
};

const formatAccommodationType = (type: string): string => {
  const accommodationTypes = {
    hotels: 'Hotels',
    apartments: 'Apartments',
    bb: 'B&Bs',
    hostels: 'Hostels',
  };
  return accommodationTypes[type as keyof typeof accommodationTypes] || type;
};

const formatBudgetRange = (budget: string): string => {
  const budgetRanges = {
    'under-50': 'Less than $50',
    '50-100': '$50-$100',
    '100-200': '$100-$200',
    '200-300': '$200-$300',
    'over-300': 'More than $300',
  };
  return budgetRanges[budget as keyof typeof budgetRanges] || budget;
};

const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

interface ItineraryDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  itineraryId: string | null;
}

interface ItineraryDetails {
  destination: string;
  status?: 'draft' | 'generated';
  generatedAt?: Date;
  destination2?: string;
  destination3?: string;
  destination4?: string;
  originCity?: string;
  needsFlight: boolean;
  needsAccommodation: boolean;
  startDate: Date;
  endDate: Date;
  transport: string;
  accommodationType?: string;
  budget: string;
  activities: string[];
  dailyActivities: Record<string, DailyActivity[]>;
  flightOptions?: FlightOption[];
  flightOptions2?: FlightOption[];
  flightOptions3?: FlightOption[];
  flightOptions4?: FlightOption[];
  accommodationOptions?: Record<string, AccommodationOption[]>;
  publicTransportInfo?: {
    overview: string;
    mainOptions: string[];
    ticketTypes: string[];
    tips: string[];
  };
}

interface DailyActivity {
  time: string;
  activity: string;
  location: string;
  notes?: string;
}

export default function ItineraryDetailsModal({
  isOpen,
  onClose,
  itineraryId,
}: ItineraryDetailsModalProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [details, setDetails] = useState<ItineraryDetails | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Helper function to safely format dates
  const formatSafeDate = (date: any, formatString: string): string => {
    try {
      if (!date) return 'N/A';
      const dateObj = date instanceof Date ? date : date.toDate?.();
      if (!dateObj || !(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        console.warn('Invalid date value:', date);
        return 'N/A';
      }
      return format(dateObj, formatString);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };
  const { status: subscriptionStatus } = useSubscriptionStatus(
    auth.currentUser?.uid
  );
  const [firstDayKey, setFirstDayKey] = useState<string | null>(null);

  // Validate data structure
  const validateDailyActivities = (
    activities: any
  ): Record<string, DailyActivity[]> => {
    console.log('Starting validation of daily activities:', activities);
    const validated: Record<string, DailyActivity[]> = {};

    // Navigate through potential nesting to find the actual daily activities
    const activitiesData = activities?.dailyActivities?.dailyActivities || activities?.dailyActivities || activities;

    if (!activitiesData || typeof activitiesData !== 'object') {
      console.warn('Invalid dailyActivities structure:', activities);
      return validated;
    }

    console.log('Processing activities data:', activitiesData);

    Object.entries(activitiesData).forEach(([date, dayActivities]) => {
      // Skip non-date properties
      if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        console.log('Skipping non-date property:', date);
        return;
      }

      if (!Array.isArray(dayActivities)) {
        console.warn(
          `Invalid activities format for date ${date}, expected array but got:`,
          dayActivities
        );
        return;
      }

      console.log(`Processing activities for date ${date}:`, dayActivities);

      validated[date] = dayActivities
        .filter(
          (activity) =>
            activity &&
            typeof activity === 'object' &&
            typeof activity.time === 'string' &&
            typeof activity.activity === 'string' &&
            typeof activity.location === 'string'
        )
        .map((activity) => ({
          time: activity.time,
          activity: activity.activity,
          location: activity.location,
          notes: activity.notes?.toString(),
        }));

      console.log(`Validated activities for date ${date}:`, validated[date]);
    });

    console.log('Final validated daily activities:', {
      dates: Object.keys(validated),
      totalActivities: Object.values(validated).reduce((sum, arr) => sum + arr.length, 0)
    });

    return validated;
  };

  // Validate flight options
  const validateFlightOptions = (options: any): FlightOption[] | undefined => {
    if (!Array.isArray(options)) return undefined;

    return options
      .filter(
        (option) =>
          option &&
          typeof option === 'object' &&
          typeof option.origin === 'string' &&
          typeof option.destination === 'string' &&
          typeof option.airline === 'string' &&
          typeof option.departureTime === 'string' &&
          typeof option.arrivalTime === 'string' &&
          typeof option.price === 'string'
      )
      .map((option) => ({
        origin: option.origin,
        destination: option.destination,
        airline: option.airline,
        departureTime: option.departureTime,
        arrivalTime: option.arrivalTime,
        price: option.price,
        notes: option.notes?.toString(),
      }));
  };

  // Validate accommodation options
  const validateAccommodationOptions = (
    options: any
  ): Record<string, AccommodationOption[]> | undefined => {
    if (!options || typeof options !== 'object') return undefined;

    const validated: Record<string, AccommodationOption[]> = {};

    Object.entries(options).forEach(([city, cityOptions]) => {
      if (!Array.isArray(cityOptions)) return;

      validated[city] = cityOptions
        .filter(
          (option) =>
            option &&
            typeof option === 'object' &&
            typeof option.name === 'string' &&
            typeof option.type === 'string' &&
            typeof option.location === 'string' &&
            typeof option.priceRange === 'string' &&
            typeof option.rating === 'string' &&
            Array.isArray(option.amenities)
        )
        .map((option) => ({
          name: option.name,
          type: option.type,
          location: option.location,
          priceRange: option.priceRange,
          rating: option.rating,
          amenities: option.amenities.map(String),
          notes: option.notes?.toString(),
        }));
    });

    return Object.keys(validated).length > 0 ? validated : undefined;
  };

  useEffect(() => {
    const fetchDetails = async () => {
      if (!itineraryId || !auth.currentUser) return;

      setLoading(true);
      setError(null);

      try {
        const docRef = doc(
          db,
          `users/${auth.currentUser.uid}/itineraries/${itineraryId}`
        );
        const docSnap = await getDoc(docRef);
        console.log('Raw itinerary data by Fer/Perp:', docSnap.data());

        if (docSnap.exists()) {
          console.log('Raw itinerary data:', docSnap.data());
          const data = docSnap.data();

          // Validate and process the data
          console.log('Processing raw data:', {
            hasNestedDailyActivities: !!data.dailyActivities?.dailyActivities,
            hasTopLevelDailyActivities: !!data.dailyActivities,
            structure: JSON.stringify(data, null, 2)
          });

          const validatedDailyActivities = validateDailyActivities(data);
          console.log('Validation complete:', {
            dates: Object.keys(validatedDailyActivities),
            activities: Object.values(validatedDailyActivities)
              .reduce((sum, arr) => sum + arr.length, 0)
          });

          const sortedDates = Object.keys(validatedDailyActivities).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
          );
          setFirstDayKey(sortedDates[0] || null);

          // Process and validate all data
          setDetails({
            ...data,
            startDate: data.startDate.toDate(),
            endDate: data.endDate.toDate(),
            dailyActivities: validatedDailyActivities,
            flightOptions: validateFlightOptions(data.flightOptions),
            flightOptions2: validateFlightOptions(data.flightOptions2),
            flightOptions3: validateFlightOptions(data.flightOptions3),
            flightOptions4: validateFlightOptions(data.flightOptions4),
            accommodationOptions: validateAccommodationOptions(
              data.accommodationOptions
            ),
          } as ItineraryDetails);
        } else {
          setError('Itinerary not found');
        }
      } catch (err) {
        console.error('Error fetching itinerary:', err);
        setError('Failed to load itinerary details');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && itineraryId) {
      fetchDetails();
    }
  }, [isOpen, itineraryId]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  // Focus trap
  useEffect(() => {
    if (!isOpen) return;

    const modal = modalRef.current;
    if (!modal) return;

    const focusableElements = modal.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusable = focusableElements[0] as HTMLElement;
    const lastFocusable = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleTab = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      } else {
        if (document.activeElement === lastFocusable) {
          e.preventDefault();
          firstFocusable.focus();
        }
      }
    };

    modal.addEventListener('keydown', handleTab);
    firstFocusable.focus();

    return () => modal.removeEventListener('keydown', handleTab);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div
        ref={modalRef}
        className="bg-white rounded-3xl w-full max-w-4xl relative animate-slide-up overflow-hidden"
        style={{ minHeight: '24rem' }}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 z-10"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>

        {loading ? (
          <div className="h-96 flex items-center justify-center">
            <div className="flex items-center gap-2 text-[#E53E3E]">
              <Loader2 size={24} className="animate-spin" />
              <span>Loading itinerary details...</span>
            </div>
          </div>
        ) : error ? (
          <div className="h-96 flex items-center justify-center">
            <div className="text-red-600 text-center">
              <p className="mb-4">{error}</p>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          details && (
            <>
              {/* Header */}
              <div className="bg-[#E53E3E] text-white p-8">
                <h2 id="modal-title" className="text-3xl font-bold mb-4">
                  {details.destination2
                    ? 'Multi-city Itinerary'
                    : details.destination}
                </h2>
                <div className="flex flex-wrap gap-4 text-white/90">
                  <div className="flex items-center gap-2">
                    <Calendar size={20} />
                    <span>
                      {formatSafeDate(details.startDate, 'MMM d')} -{' '}
                      {formatSafeDate(details.endDate, 'MMM d, yyyy')}
                    </span>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div
                ref={contentRef}
                className="max-h-[calc(100vh-16rem)] overflow-y-auto divide-y divide-gray-100"
              >
                {/* Destinations */}
                <div className="p-8">
                  <div className="flex items-center gap-3 mb-6">
                    <MapPin className="text-[#E53E3E]" size={24} />
                    <h3 className="text-xl font-bold text-[#2C3E50]">
                      Your Destinations
                    </h3>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-gray-50 rounded-2xl p-6">
                      <div className="flex items-center gap-3">
                        <div className="w-8 h-8 bg-[#E53E3E] text-white rounded-full flex items-center justify-center font-semibold">
                          1
                        </div>
                        <div>
                          <h4 className="text-lg font-semibold text-[#2C3E50]">
                            {details.destination}
                          </h4>
                          <p className="text-sm text-gray-600">
                            Primary Destination
                          </p>
                        </div>
                      </div>
                    </div>
                    {details.destination2 && (
                      <div className="bg-gray-50 rounded-2xl p-6">
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 bg-[#E53E3E] text-white rounded-full flex items-center justify-center font-semibold">
                            2
                          </div>
                          <div>
                            <h4 className="text-lg font-semibold text-[#2C3E50]">
                              {details.destination2}
                            </h4>
                            <p className="text-sm text-gray-600">Second Stop</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details.destination3 && (
                      <div className="bg-gray-50 rounded-2xl p-6">
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 bg-[#E53E3E] text-white rounded-full flex items-center justify-center font-semibold">
                            3
                          </div>
                          <div>
                            <h4 className="text-lg font-semibold text-[#2C3E50]">
                              {details.destination3}
                            </h4>
                            <p className="text-sm text-gray-600">Third Stop</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {details.destination4 && (
                      <div className="bg-gray-50 rounded-2xl p-6">
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 bg-[#E53E3E] text-white rounded-full flex items-center justify-center font-semibold">
                            4
                          </div>
                          <div>
                            <h4 className="text-lg font-semibold text-[#2C3E50]">
                              {details.destination4}
                            </h4>
                            <p className="text-sm text-gray-600">Final Stop</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Trip Overview */}
                <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {details.needsFlight && details.originCity && (
                    <div className="bg-gray-50 rounded-2xl p-6">
                      <div className="flex items-center gap-3 mb-4">
                        <Plane className="text-[#E53E3E]" size={24} />
                        <h3 className="text-lg font-semibold">Origin City</h3>
                      </div>
                      <p className="text-gray-600">{details.originCity}</p>
                    </div>
                  )}

                  <div className="bg-gray-50 rounded-2xl p-6">
                    <div className="flex items-center gap-3 mb-4">
                      <Car className="text-[#E53E3E]" size={24} />
                      <h3 className="text-lg font-semibold">Transportation</h3>
                    </div>
                    <p className="text-gray-600">
                      {formatTransportType(details.transport)}
                    </p>
                  </div>

                  {details.accommodationType && (
                    <div className="bg-gray-50 rounded-2xl p-6">
                      <div className="flex items-center gap-3 mb-4">
                        <Hotel className="text-[#E53E3E]" size={24} />
                        <h3 className="text-lg font-semibold">Accommodation</h3>
                      </div>
                      <p className="text-gray-600">
                        {formatAccommodationType(details.accommodationType)}
                      </p>
                    </div>
                  )}

                  <div className="bg-gray-50 rounded-2xl p-6">
                    <div className="flex items-center gap-3 mb-4">
                      <Wallet className="text-[#E53E3E]" size={24} />
                      <h3 className="text-lg font-semibold">Budget Range</h3>
                    </div>
                    <p className="text-gray-600">
                      {formatBudgetRange(details.budget)}
                    </p>
                  </div>
                </div>

                {/* Selected Activities */}
                {details.activities && details.activities.length > 0 && (
                  <div className="p-8">
                    <div className="flex items-center gap-3 mb-6">
                      <ListChecks className="text-[#E53E3E]" size={24} />
                      <h3 className="text-xl font-bold text-[#2C3E50]">
                        Selected Activities
                      </h3>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                      {details.activities.map((activity) => (
                        <div
                          key={activity}
                          className="bg-gray-50 rounded-xl p-4 text-gray-700"
                        >
                          {formatActivityLabel(activity)}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Flight Options Section */}
                {(details.flightOptions || details.flightOptions2 || details.flightOptions3 || details.flightOptions4) && (
                  <div className="p-8">
                    <div className="flex items-center gap-3 mb-6">
                      <Plane className="text-[#E53E3E]" size={24} />
                      <h3 className="text-xl font-bold text-[#2C3E50]">
                        Flight Options {details.destination2 ? '(Multi-City)' : ''}
                      </h3>
                    </div>
                    <div className="space-y-4">
                      {/* Primary Flight */}
                      {details.flightOptions && details.flightOptions.length > 0 && (
                        <div>
                          <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                            {details.originCity} → {details.destination}
                          </h4>
                      {details.flightOptions.map((flight, index) => (
                        <div key={index} className="bg-gray-50 rounded-2xl p-6">
                          <div className="flex flex-col gap-3">
                            <div className="flex justify-between items-center">
                              <div>
                                <h4 className="font-semibold text-[#2C3E50]">{flight.airline}</h4>
                                <p className="text-sm text-gray-600">{flight.origin} → {flight.destination}</p>
                              </div>
                              <div className="text-right">
                                <p className="font-semibold text-[#E53E3E]">{flight.price}</p>
                                <p className="text-sm text-gray-600">{flight.departureTime} - {flight.arrivalTime}</p>
                              </div>
                            </div>
                            {flight.notes && (
                              <p className="text-sm text-gray-500 italic">{flight.notes}</p>
                            )}
                          </div>
                        </div>
                      ))}
                        </div>
                      )}

                      {/* Second Flight */}
                      {details.flightOptions2 && details.flightOptions2.length > 0 && (
                        <div className="mt-8">
                          <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                            {details.destination} → {details.destination2}
                          </h4>
                          {details.flightOptions2.map((flight, index) => (
                            <div key={index} className="bg-gray-50 rounded-2xl p-6">
                              <div className="flex flex-col gap-3">
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h4 className="font-semibold text-[#2C3E50]">{flight.airline}</h4>
                                    <p className="text-sm text-gray-600">{flight.origin} → {flight.destination}</p>
                                  </div>
                                  <div className="text-right">
                                    <p className="font-semibold text-[#E53E3E]">{flight.price}</p>
                                    <p className="text-sm text-gray-600">{flight.departureTime} - {flight.arrivalTime}</p>
                                  </div>
                                </div>
                                {flight.notes && (
                                  <p className="text-sm text-gray-500 italic">{flight.notes}</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Third Flight */}
                      {details.flightOptions3 && details.flightOptions3.length > 0 && (
                        <div className="mt-8">
                          <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                            {details.destination2} → {details.destination3}
                          </h4>
                          {details.flightOptions3.map((flight, index) => (
                            <div key={index} className="bg-gray-50 rounded-2xl p-6">
                              <div className="flex flex-col gap-3">
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h4 className="font-semibold text-[#2C3E50]">{flight.airline}</h4>
                                    <p className="text-sm text-gray-600">{flight.origin} → {flight.destination}</p>
                                  </div>
                                  <div className="text-right">
                                    <p className="font-semibold text-[#E53E3E]">{flight.price}</p>
                                    <p className="text-sm text-gray-600">{flight.departureTime} - {flight.arrivalTime}</p>
                                  </div>
                                </div>
                                {flight.notes && (
                                  <p className="text-sm text-gray-500 italic">{flight.notes}</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Fourth Flight */}
                      {details.flightOptions4 && details.flightOptions4.length > 0 && (
                        <div className="mt-8">
                          <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                            {details.destination3} → {details.destination4}
                          </h4>
                          {details.flightOptions4.map((flight, index) => (
                            <div key={index} className="bg-gray-50 rounded-2xl p-6">
                              <div className="flex flex-col gap-3">
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h4 className="font-semibold text-[#2C3E50]">{flight.airline}</h4>
                                    <p className="text-sm text-gray-600">{flight.origin} → {flight.destination}</p>
                                  </div>
                                  <div className="text-right">
                                    <p className="font-semibold text-[#E53E3E]">{flight.price}</p>
                                    <p className="text-sm text-gray-600">{flight.departureTime} - {flight.arrivalTime}</p>
                                  </div>
                                </div>
                                {flight.notes && (
                                  <p className="text-sm text-gray-500 italic">{flight.notes}</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {/* Flight Disclaimer */}
                      <div className="bg-blue-50 border border-blue-200 rounded-xl p-4 mt-8">
                        <div className="flex items-start gap-3">
                          <AlertTriangle className="text-blue-800 flex-shrink-0 mt-0.5" size={18} />
                          <p className="text-sm text-blue-800">
                          Please note that all flight information shown is for reference purposes only. We recommend conducting your own research and verification of flight details, prices, and availability before making any bookings. Flight schedules and prices may vary, and this information should be used as a general guide for orientation purposes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Accommodation Options Section */}
                {details.accommodationOptions && Object.keys(details.accommodationOptions).length > 0 && (
                  <div className="p-8">
                    <div className="flex items-center gap-3 mb-6">
                      <Hotel className="text-[#E53E3E]" size={24} />
                      <h3 className="text-xl font-bold text-[#2C3E50]">
                        Accommodation Options
                      </h3>
                    </div>
                    {Object.entries(details.accommodationOptions).map(([city, options]) => (
                      <div key={city} className="space-y-4">
                        <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">{city}</h4>
                        {options.map((option, index) => (
                          <div key={index} className="bg-gray-50 rounded-2xl p-6">
                            <div className="flex flex-col gap-3">
                              <div className="flex justify-between items-start">
                                <div>
                                  <h5 className="font-semibold text-[#2C3E50]">{option.name}</h5>
                                  <p className="text-sm text-gray-600">{option.location}</p>
                                </div>
                                <div className="text-right">
                                  <p className="font-semibold text-[#E53E3E]">{option.priceRange}</p>
                                  <p className="text-sm text-gray-600">Rating: {option.rating}</p>
                                </div>
                              </div>
                              <div className="flex flex-wrap gap-2">
                                {option.amenities.map((amenity, i) => (
                                  <span key={i} className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full">
                                    {amenity}
                                  </span>
                                ))}
                              </div>
                              {option.notes && (
                                <p className="text-sm text-gray-500 italic">{option.notes}</p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    {/* Accommodation Disclaimer */}
                    <div className="bg-blue-50 border border-blue-200 rounded-xl p-4 mt-8">
                      <div className="flex items-start gap-3">
                        <AlertTriangle className="text-blue-800 flex-shrink-0 mt-0.5" size={18} />
                        <p className="text-sm text-blue-800">
                          Please note that all accommodation information shown is for reference purposes only. We recommend verifying availability, prices, and amenities directly with the properties before making any bookings. Room rates and availability may vary, and this information should be used as a general guide for orientation purposes.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Transportation Options Section */}
                {details.transport !== 'public' && details.transportationOptions && details.transportationOptions.length > 0 && (
                  <div className="p-8">
                    <div className="flex items-center gap-3 mb-6">
                      <Car className="text-[#E53E3E]" size={24} />
                      <h3 className="text-xl font-bold text-[#2C3E50]">
                        Transportation Options
                      </h3>
                    </div>
                    <div className="space-y-4">
                      {details.transportationOptions.map((option, index) => (
                        <div key={index} className="bg-gray-50 rounded-2xl p-6">
                          <div className="flex flex-col gap-3">
                            <div className="flex justify-between items-center">
                              <div>
                                <h4 className="font-semibold text-[#2C3E50]">{option.provider}</h4>
                              </div>
                              <div className="text-right">
                                <p className="font-semibold text-[#E53E3E]">{option.priceRange}</p>
                                <p className="text-sm text-gray-600">{option.coverage}</p>
                              </div>
                            </div>
                            {option.notes && (
                              <p className="text-sm text-gray-500 italic">{option.notes}</p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Public Transport Info Section */}
                {details.transport === 'public' && details.publicTransportInfo && (
                  <div className="p-8">
                    <div className="flex items-center gap-3 mb-6">
                      <Car className="text-[#E53E3E]" size={24} />
                      <h3 className="text-xl font-bold text-[#2C3E50]">
                        Public Transportation
                      </h3>
                    </div>
                    <div className="bg-gray-50 rounded-2xl p-6 space-y-6">
                      <div>
                        <h4 className="font-semibold text-[#2C3E50] mb-2">Overview</h4>
                        <p className="text-gray-600">{details.publicTransportInfo.overview}</p>
                      </div>
                      
                      <div>
                        <h4 className="font-semibold text-[#2C3E50] mb-2">Available Options</h4>
                        <div className="flex flex-wrap gap-2">
                          {details.publicTransportInfo.mainOptions.map((option, index) => (
                            <span key={index} className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">
                              {option}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h4 className="font-semibold text-[#2C3E50] mb-2">Ticket Types</h4>
                        <div className="flex flex-wrap gap-2">
                          {details.publicTransportInfo.ticketTypes.map((ticket, index) => (
                            <span key={index} className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">
                              {ticket}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h4 className="font-semibold text-[#2C3E50] mb-2">Travel Tips</h4>
                        <ul className="list-disc list-inside space-y-2">
                          {details.publicTransportInfo.tips.map((tip, index) => (
                            <li key={index} className="text-gray-600">{tip}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* Generation Status */}
                {details.status === 'draft' && (
                  <div className="p-8 text-center">
                    <div className="flex items-center justify-center gap-2 text-[#E53E3E] mb-4">
                      <Loader2 size={24} className="animate-spin" />
                      <span>Generating your personalized itinerary...</span>
                    </div>
                    <p className="text-gray-600">
                      We're using AI to create a custom itinerary based on your
                      preferences. This may take a few moments.
                    </p>
                  </div>
                )}

                {/* Daily Activities Section */}
                {details.status === 'generated' &&
                  details.dailyActivities &&
                  firstDayKey && (
                    <div className="p-8 space-y-6">
                      <div className="flex items-center gap-3 mb-6">
                        <Calendar className="text-[#E53E3E]" size={24} />
                        <h3 className="text-xl font-bold text-[#2C3E50]">
                          {Object.keys(details.dailyActivities).length > 0
                            ? 'Daily Schedule'
                            : 'No Activities Scheduled'}
                        </h3>
                      </div>

                      {/* First Day's Activities (Always Shown) */}
                      {firstDayKey &&
                      Array.isArray(details.dailyActivities[firstDayKey]) ? (
                        <div
                          key={firstDayKey}
                          className="bg-gray-50 rounded-2xl p-6"
                        >
                          <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                            {formatSafeDate(
                              new Date(firstDayKey),
                              'EEEE, MMMM d'
                            )}
                          </h4>
                          <div className="space-y-4">
                            {details.dailyActivities[firstDayKey].map(
                              (activity, index) => (
                                <div
                                  key={index}
                                  className="flex items-start gap-4 p-4 bg-white rounded-xl"
                                >
                                  <div className="flex-shrink-0 w-16 text-sm font-medium text-gray-600">
                                    {activity.time}
                                  </div>
                                  <div>
                                    <h5 className="font-medium text-[#2C3E50] mb-1">
                                      {capitalizeFirstLetter(activity.activity)}
                                    </h5>
                                    <p className="text-sm text-gray-600 mb-2">
                                      {capitalizeFirstLetter(activity.location)}
                                    </p>
                                    {activity.notes && (
                                      <p className="text-sm text-gray-500 italic">
                                        {capitalizeFirstLetter(activity.notes)}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="text-center text-gray-600 py-4">
                          No activities scheduled for this day.
                        </div>
                      )}

                      {/* Remaining Days (Premium Only) */}
                      {subscriptionStatus === 'active' ||
                      subscriptionStatus === 'canceling' ? (
                        Object.entries(details.dailyActivities)
                          .sort(([dateA], [dateB]) => {
                            const a = new Date(dateA);
                            const b = new Date(dateB);
                            return a.getTime() - b.getTime();
                          })
                          .filter(([date]) => date !== firstDayKey)
                          .map(([date, activities]) => (
                            <div
                              key={date}
                              className="bg-gray-50 rounded-2xl p-6"
                            >
                              <h4 className="text-lg font-semibold text-[#2C3E50] mb-4">
                                {formatSafeDate(new Date(date), 'EEEE, MMMM d')}
                              </h4>
                              <div className="space-y-4">
                                {activities.map((activity, index) => (
                                  <div
                                    key={index}
                                    className="flex items-start gap-4 p-4 bg-white rounded-xl"
                                  >
                                    <div className="flex-shrink-0 w-16 text-sm font-medium text-gray-600">
                                      {activity.time}
                                    </div>
                                    <div>
                                      <h5 className="font-medium text-[#2C3E50] mb-1">
                                        {capitalizeFirstLetter(
                                          activity.activity
                                        )}
                                      </h5>
                                      <p className="text-sm text-gray-600 mb-2">
                                        {capitalizeFirstLetter(
                                          activity.location
                                        )}
                                      </p>
                                      {activity.notes && (
                                        <p className="text-sm text-gray-500 italic">
                                          {capitalizeFirstLetter(
                                            activity.notes
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="bg-gradient-to-r from-[#E53E3E]/5 to-red-700/5 rounded-3xl p-8 text-center">
                          <div className="w-16 h-16 bg-[#E53E3E]/10 rounded-full flex items-center justify-center mx-auto mb-4">
                            <Lock className="text-[#E53E3E]" size={24} />
                          </div>
                          <h4 className="text-xl font-semibold text-[#2C3E50] mb-2">
                            Unlock Full Itinerary
                          </h4>
                          <p className="text-gray-600 mb-6 max-w-md mx-auto">
                            Get access to your complete travel schedule,
                            including all destinations and activities, with our
                            Unlimited Plan.
                          </p>
                          <button
                            onClick={() => {
                              onClose();
                              navigate('/checkout');
                            }}
                            className="px-6 py-3 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors inline-flex items-center gap-2"
                          >
                            <Infinity size={20} />
                            Upgrade to Unlimited
                          </button>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}

