import React from 'react';
import { 
  Car, 
  Hotel, 
  UtensilsCrossed, 
  Ticket, 
  MapPin, 
  MessageCircle,
  Calendar,
  Compass
} from 'lucide-react';

const features = [
  {
    icon: Car,
    title: 'Smart Transportation',
    description: 'Optimal routes and transport options for seamless travel between destinations.'
  },
  {
    icon: Hotel,
    title: 'Curated Stays',
    description: 'Hand-picked accommodations matching your style and budget.'
  },
  {
    icon: UtensilsCrossed,
    title: 'Local Dining',
    description: 'Best restaurants and hidden gems for authentic local cuisine.'
  },
  {
    icon: Ticket,
    title: 'Activity Finding',
    description: 'For those that want to go beyond the conventional travel experience.'
  },
  {
    icon: MapPin,
    title: 'Points of Interest',
    description: 'Must-see attractions and off-the-beaten-path discoveries.'
  },
  {
    icon: MessageCircle,
    title: 'Local Tips',
    description: 'Insider knowledge from residents and seasoned travelers.'
  },
  {
    icon: Calendar,
    title: 'Smart Scheduling',
    description: 'Optimized daily planning considering opening hours and crowds.'
  },
  {
    icon: Compass,
    title: 'Custom Routes',
    description: 'Personalized walking routes connecting nearby attractions.'
  }
];

export default function FeaturesGrid() {
  return (
    <section id="features" className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-[#2C3E50] mb-4">
          Everything You Need
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          From transportation to local tips, we've got every aspect of your journey covered when you upgrade your itinerary
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white rounded-3xl p-6 shadow-lg hover:shadow-xl transition-shadow group"
            >
              <div className="w-12 h-12 bg-[#E53E3E]/10 rounded-3xl flex items-center justify-center mb-4 group-hover:bg-[#E53E3E] transition-colors">
                <feature.icon 
                  size={24}
                  className="text-[#E53E3E] group-hover:text-white transition-colors"
                />
              </div>
              <h3 className="text-lg font-semibold text-[#2C3E50] mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 text-sm">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}