import {
  GeneratedItineraryResponse,
  FlightOption,
  AccommodationOption,
  TransportationOption,
  PublicTransportInfo,
} from './types';

const PERPLEXITY_API_KEY =
  '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';
const API_URL = 'https://api.perplexity.ai/chat/completions';

export class PerplexityService {
  async generateItinerary(input: {
    destinations: string[];
    startDate: Date;
    endDate: Date;
    activities: string[];
    transport: string;
    firstVisit: boolean;
    needsAccommodation: boolean;
    needsFlight: boolean;
    budget: string;
    partySize: { adults: number; children: number };
  }): Promise<GeneratedItineraryResponse> {
    // Input validation
    if (!input.destinations || input.destinations.length === 0) {
      throw new Error('At least one destination is required');
    }

    if (!input.activities || input.activities.length === 0) {
      throw new Error('At least one activity must be selected');
    }

    if (!input.budget) {
      throw new Error('Budget selection is required');
    }

    if (!input.transport) {
      throw new Error('Transportation preference is required');
    }

    if (input.partySize.adults < 1) {
      throw new Error('At least one adult traveler is required');
    }

    try {
      if (!input.startDate || !input.endDate) {
        throw new Error('Start and end dates are required');
      }

      const prompt = this.buildPrompt(input);

      let retries = 3;
      let lastError: Error | null = null;
      let backoffDelay = 1000; // Start with 1 second delay

      while (retries > 0) {
        try {
          const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${PERPLEXITY_API_KEY}`,
            },
            body: JSON.stringify({
              model: 'sonar',
              messages: [
                {
                  role: 'system',
                  content:
                    'You are an expert travel planner. Generate detailed daily itineraries in JSON format. Return ONLY valid JSON without any markdown formatting or wrapper text.',
                },
                {
                  role: 'user',
                  content: prompt,
                },
              ],
              max_tokens: 4000,
              temperature: 0.3, // Reduced for more consistent outputs
              top_p: 0.9, // Added for better response quality
            }),
          });

          const responseData = await response.json().catch(() => null);

          if (!response.ok || !responseData) {
            console.error('API request failed:', {
              status: response.status,
              statusText: response.statusText,
              responseData,
            });
            lastError = new Error(
              responseData?.error?.message ||
                `API request failed: ${response.status} ${response.statusText}`
            );
            throw lastError;
          }

          if (!responseData?.choices?.[0]?.message?.content) {
            console.error('Invalid API response structure:', responseData);
            lastError = new Error('Invalid response format from API');
            throw lastError;
          }

          let parsedContent;
          try {
            const content = responseData.choices[0].message.content;
            // Remove any markdown formatting if present
            const jsonContent = content
              .replace(/^```json\n|\n```$/g, '')
              .trim();
            try {
              parsedContent = JSON.parse(jsonContent);
            } catch (parseError) {
              console.error('JSON parse error:', parseError);
              console.error('Raw content:', jsonContent);
              throw new Error(
                'Failed to parse AI response: Invalid JSON format'
              );
            }
          } catch (e) {
            console.error('Failed to parse API response:', e);
            lastError = new Error(`Failed to parse API response: ${e.message}`);
            throw lastError;
          }

          // Validate response structure
          if (
            !parsedContent ||
            typeof parsedContent !== 'object' ||
            Array.isArray(parsedContent)
          ) {
            console.error('Invalid response structure:', {
              isParsedContentNull: !parsedContent,
              type: typeof parsedContent,
              isArray: Array.isArray(parsedContent),
            });
            lastError = new Error(
              'Invalid response structure: expected an object with dates'
            );
            throw lastError;
          }

          // Process and validate the response
          const processedResponse = this.processResponse(parsedContent, input);

          return processedResponse;
        } catch (error) {
          console.error(`Attempt ${4 - retries}/3 failed:`, error);
          retries--;
          if (retries === 0) {
            throw error;
          }

          // Exponential backoff with jitter
          const jitter = Math.random() * 1000;
          const totalDelay = backoffDelay + jitter;
          await new Promise((resolve) => setTimeout(resolve, totalDelay));
          backoffDelay *= 2; // Double the delay for next retry
        }
      }

      throw (
        lastError ||
        new Error('Failed to generate itinerary after multiple attempts')
      );
    } catch (error) {
      // Enhance error details
      const enhancedError = new Error(
        error instanceof Error
          ? `Itinerary generation failed: ${error.message}`
          : 'Failed to generate itinerary: Unknown error'
      );
      // Add additional context to the error
      Object.assign(enhancedError, {
        context: {
          destinations: input.destinations,
          activities: input.activities,
          transport: input.transport,
          budget: input.budget,
        },
      });
      console.error('Error generating itinerary:', {
        error: enhancedError,
        originalError: error,
        input: {
          ...input,
          startDate: input.startDate?.toISOString(),
          endDate: input.endDate?.toISOString(),
        },
      });
      throw error;
    }
  }

  private buildPrompt(input: any): string {
    const days = Math.ceil(
      (input.endDate.getTime() - input.startDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    const activities = input.activities.join(', ');
    const travelers = `${input.partySize.adults} adults${
      input.partySize.children
        ? ` and ${input.partySize.children} children`
        : ''
    }`;

    // Build the base prompt
    const prompt = `Assume the role of an expert travel planner, and create a detailed ${days}-day travel itinerary as a valid JSON object with the following requirements:

Primary destination: ${input.destinations[0]}
${
  input.destinations.slice(1).length > 0
    ? `Additional destinations: ${input.destinations.slice(1).join(', ')}\n`
    : ''
}
Dates: ${input.startDate.toLocaleDateString()} to ${input.endDate.toLocaleDateString()}
Group: ${travelers}
Transportation: ${input.transport}
Budget range: ${input.budget}
Preferred activities: ${activities}
First time visit: ${input.firstVisit ? 'Yes' : 'No'}
Flight booking: ${input.needsFlight ? 'Yes' : 'No'}
Accommodation booking: ${input.needsAccommodation ? 'Yes' : 'No'}

The response should be a JSON object with the following structure:
{
  "dailyActivities": {
    "YYYY-MM-DD": [
      {
        "time": "HH:MM",
        "activity": "Activity description",
        "location": "Specific location name",
        "notes": "Optional additional information"
      }
    ]
  }${
    input.needsFlight
      ? `,
  "flightOptions": [
    {
      "origin": "${input.originCity}", 
      "destination": "${input.destinations[0]}",
      "airline": "Provide a real airline that operates this route",
      "departureTime": "Provide a realistic departure time based on actual flight schedules",
      "arrivalTime": "Calculate realistic arrival time considering flight duration and time zones",
      "price": "Provide current price range based on market rates",
      "notes": "Include important details like layovers, flight duration, and baggage allowance"
    }
  ]${input.destinations
    .slice(1)
    .map(
      (dest, index) => `
  "flightOptions${index + 2}": [
    {
      "origin": "${input.destinations[index]}", 
      "destination": "${dest}",
      "airline": "Provide a real airline that operates this route",
      "departureTime": "Provide a realistic departure time based on actual flight schedules",
      "arrivalTime": "Calculate realistic arrival time considering flight duration and time zones",
      "price": "Provide current price range based on market rates",
      "notes": "Include important details like layovers, flight duration, and baggage allowance"
    }
  ]`
    )
    .join('')}`
      : ''
  }${
      input.needsAccommodation
        ? `,
  "accommodationOptions": {${input.destinations
    .map(
      (dest, index) => `
    "${dest}": [
      {
        "name": "Property name",
        "type": "${input.accommodationType}",
        "location": "Area/neighborhood",
        "priceRange": "Price per night",
        "rating": "Rating out of 5",
        "amenities": ["Key amenities"],
        "notes": "Additional details"
      }
    ]`
    )
    .join(',')}
  }`
        : ''
    }${
      input.transport !== 'public'
        ? `,
  "transportationOptions": [
    {
      "type": "${input.transport}",
      "provider": "Company name",
      "priceRange": "Estimated cost",
      "coverage": "Service area/restrictions",
      "notes": "Additional details"
    }
  ]`
        : input.transport === 'public'
        ? `,
  "publicTransportInfo": {
    "overview": "Brief overview of the public transportation system",
    "mainOptions": ["Available transport types"],
    "ticketTypes": ["Available ticket options"],
    "tips": ["Important tips for using public transport"]
  }`
        : ''
    }
}

Consider:
1. Opening hours of attractions
2. Travel time between locations
3. Meal times and local dining customs
4. Activity difficulty and group composition
5. Local events and seasonal factors
6. Budget constraints
7. Logical geographical flow
8. Rest periods and flexibility

For transportation options:
- If taxi: Include major taxi companies, ride-sharing services, and estimated costs
- If rental: Include top rental agencies, car types, and requirements
- If public transport: Focus on passes, routes, and system navigation

For accommodations:
- Match options to the specified budget range
- The term "bb" means "Bed and Breakfast"
- Provide at least 3 properties per city
- Prioritize properties with higher ratings within the budget range
- Include current market price ranges for the specified dates
- Consider proximity to planned activities
- Include properties with good ratings and reviews
- Focus on ${input.accommodationType} that match the group size

For flights:
- Research and provide REAL airlines that actually operate on these routes
- Use realistic flight times based on actual airline schedules
- Consider time zones and calculate accurate arrival times
- Include current market price ranges for the specified dates
- Mention if the route typically has direct flights or requires layovers
- Include important details about baggage allowance and airline policies
- Consider airport-specific information (main terminals, transfer times)
- Note any visa requirements or transit considerations
- Consider alliance partnerships and codeshare agreements

DO NOT include any explanatory text or markdown, ONLY return the JSON object.`;

    return prompt;
  }

  private processResponse(
    content: any,
    input: any
  ): GeneratedItineraryResponse {
    try {
      // Validate and clean the response
      console.log('Processing AI response:', content);

      if (!content || typeof content !== 'object') {
        throw new Error('Invalid response format: expected an object');
      }

      // Get daily activities from the correct location in the response
      let dailyActivitiesData =
        content.dailyActivities?.dailyActivities ||
        content.dailyActivities ||
        {};

      // Ensure dailyActivities is an object with date keys
      if (Array.isArray(dailyActivitiesData)) {
        console.warn(
          'dailyActivities is an array, converting to object format'
        );
        const startDate = input.startDate;
        dailyActivitiesData = {
          [startDate.toISOString().split('T')[0]]: dailyActivitiesData,
        };
      }

      console.log('Extracted daily activities data:', {
        fromNested: !!content.dailyActivities?.dailyActivities,
        fromTopLevel: !!content.dailyActivities,
        keys: Object.keys(dailyActivitiesData),
        rawData: JSON.stringify(dailyActivitiesData, null, 2),
      });

      // Initialize the processed response
      const processedResponse: GeneratedItineraryResponse = {
        dailyActivities: {},
      };

      console.log('Starting daily activities processing');

      // Process daily activities
      Object.entries(dailyActivitiesData).forEach(([date, activities]) => {
        console.log(`Processing date ${date}`);

        // Validate date format
        if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
          console.warn('Skipping invalid date format:', {
            date,
            matches: date.match(/\d{4}-\d{2}-\d{2}/),
            isValid: /^\d{4}-\d{2}-\d{2}$/.test(date),
          });
          return;
        }

        if (!Array.isArray(activities)) {
          console.warn('Skipping invalid activities format:', {
            date,
            type: typeof activities,
            value: activities,
          });
          return;
        }

        const validActivities = activities
          .filter(
            (activity) =>
              activity &&
              typeof activity === 'object' &&
              activity.time?.toString() &&
              activity.activity?.toString() &&
              activity.location?.toString()
          )
          .map((activity) => ({
            time: activity.time.toString(),
            activity: activity.activity.trim(),
            location: activity.location.trim(),
            notes: activity.notes?.trim() || undefined,
          }));

        console.log(`Validation results for ${date}:`, {
          beforeCount: activities.length,
          afterCount: validActivities.length,
          validActivities: JSON.stringify(validActivities, null, 2),
          failedValidation: activities.length - validActivities.length,
        });

        processedResponse.dailyActivities[date] = validActivities;
      });

      console.log('Final processed daily activities:', {
        totalDates: Object.keys(processedResponse.dailyActivities).length,
        totalActivities: Object.values(
          processedResponse.dailyActivities
        ).reduce((sum, activities) => sum + activities.length, 0),
        structure: JSON.stringify(processedResponse.dailyActivities, null, 2),
      });

      // Process flight options if needed
      if (input.needsFlight) {
        const flightOptions =
          content.flightOptions || content.dailyActivities?.flightOptions || [];
        console.log('Processing flight options:', {
          hasTopLevel: !!content.flightOptions,
          hasNested: !!content.dailyActivities?.flightOptions,
          optionsCount: flightOptions.length,
          rawOptions: JSON.stringify(flightOptions, null, 2),
        });
        if (flightOptions) {
          processedResponse.flightOptions =
            this.validateFlightOptions(flightOptions);
        }

        // Process additional flight options for multi-city trips
        for (let i = 2; i <= 4; i++) {
          const key = `flightOptions${i}`;
          const options = content[key] || content.dailyActivities?.[key] || [];
          if (options) {
            processedResponse[key] = this.validateFlightOptions(options);
          }
        }
      }

      // Process accommodation options if needed
      if (input.needsAccommodation) {
        const accommodationOptions =
          content.accommodationOptions ||
          content.dailyActivities?.accommodationOptions ||
          {};
        console.log('Processing accommodation options:', {
          hasTopLevel: !!content.accommodationOptions,
          hasNested: !!content.dailyActivities?.accommodationOptions,
          cities: Object.keys(accommodationOptions),
          rawOptions: JSON.stringify(accommodationOptions, null, 2),
        });

        if (accommodationOptions) {
          processedResponse.accommodationOptions = {};
          for (const [city, options] of Object.entries(accommodationOptions)) {
            processedResponse.accommodationOptions[city] =
              this.validateAccommodationOptions(options);
          }
        }
      }

      // Process transportation options
      if (input.transport !== 'public') {
        const transportOptions =
          content.transportationOptions ||
          content.dailyActivities?.transportationOptions ||
          [];
        if (transportOptions) {
          processedResponse.transportationOptions =
            this.validateTransportationOptions(transportOptions);
        }
      } else if (input.transport === 'public') {
        const transportInfo =
          content.publicTransportInfo ||
          content.dailyActivities?.publicTransportInfo ||
          {};
        if (transportInfo) {
          processedResponse.publicTransportInfo =
            this.validatePublicTransportInfo(transportInfo);
        }
      }

      // Log the final processed response
      console.log('Successfully processed response:', {
        totalDays: Object.keys(processedResponse.dailyActivities).length,
        totalActivities: Object.values(
          processedResponse.dailyActivities
        ).reduce((sum, activities) => sum + activities.length, 0),
      });

      return processedResponse;
    } catch (error) {
      const enhancedError = new Error(
        `Failed to process AI response: ${
          error instanceof Error ? error.message : 'Invalid response format'
        }`
      );
      console.error('Error processing AI response:', {
        error: enhancedError,
        originalError: error,
        content: JSON.stringify(content, null, 2),
      });
      throw enhancedError;
    }
  }

  private validateFlightOptions(options: any[]): FlightOption[] {
    if (!Array.isArray(options)) return [];

    return options
      .filter(
        (option) =>
          option &&
          typeof option === 'object' &&
          typeof option.origin === 'string' &&
          typeof option.destination === 'string' &&
          typeof option.airline === 'string' &&
          typeof option.departureTime === 'string' &&
          typeof option.arrivalTime === 'string' &&
          typeof option.price === 'string'
      )
      .map((option) => ({
        origin: option.origin.trim(),
        destination: option.destination.trim(),
        airline: option.airline.trim(),
        departureTime: option.departureTime.trim(),
        arrivalTime: option.arrivalTime.trim(),
        price: option.price.trim(),
        notes: option.notes?.trim(),
      }));
  }

  private validateAccommodationOptions(options: any[]): AccommodationOption[] {
    if (!Array.isArray(options)) return [];

    return options
      .filter(
        (option) =>
          option &&
          typeof option === 'object' &&
          typeof option.name === 'string' &&
          typeof option.type === 'string' &&
          typeof option.location === 'string' &&
          typeof option.priceRange === 'string' &&
          typeof option.rating === 'string' &&
          Array.isArray(option.amenities)
      )
      .map((option) => ({
        name: option.name.trim(),
        type: option.type.trim(),
        location: option.location.trim(),
        priceRange: option.priceRange.trim(),
        rating: option.rating.trim(),
        amenities: option.amenities.map((a: string) => a.trim()),
        notes: option.notes?.trim(),
      }));
  }

  private validateTransportationOptions(
    options: any[]
  ): TransportationOption[] {
    if (!Array.isArray(options)) return [];

    return options
      .filter(
        (option) =>
          option &&
          typeof option === 'object' &&
          typeof option.type === 'string' &&
          typeof option.provider === 'string' &&
          typeof option.priceRange === 'string' &&
          typeof option.coverage === 'string'
      )
      .map((option) => ({
        type: option.type.trim(),
        provider: option.provider.trim(),
        priceRange: option.priceRange.trim(),
        coverage: option.coverage.trim(),
        notes: option.notes?.trim(),
      }));
  }

  private validatePublicTransportInfo(info: any): PublicTransportInfo {
    if (!info || typeof info !== 'object') {
      return {
        overview: '',
        mainOptions: [],
        ticketTypes: [],
        tips: [],
      };
    }

    return {
      overview: typeof info.overview === 'string' ? info.overview.trim() : '',
      mainOptions: Array.isArray(info.mainOptions)
        ? info.mainOptions.map((o: string) => o.trim())
        : [],
      ticketTypes: Array.isArray(info.ticketTypes)
        ? info.ticketTypes.map((t: string) => t.trim())
        : [],
      tips: Array.isArray(info.tips)
        ? info.tips.map((t: string) => t.trim())
        : [],
    };
  }
}
