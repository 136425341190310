import { Stripe } from 'stripe';
import { db } from './firebase';
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';

// Initialize Stripe with the secret key
const stripe = new Stripe(import.meta.env.VITE_STRIPE_SECRET_KEY || 'sk_test_51QjP22ENBBUw7VQ7KsRfWgTXzhdwsUZ3Q4f0iNnrQQEmFkkDZ6TTC80FvUmaPny5SyESwd4HyaJXVqyzfVnnN6nE00P37bY5wU', {
  apiVersion: '2023-10-16',
  typescript: true,
});

export const createStripeCustomer = async (userId: string, email: string, name?: string) => {
  try {
    const customer = await stripe.customers.create({
      email,
      name: name || undefined,
      metadata: {
        firebaseUserId: userId,
        createdAt: new Date().toISOString(),
      },
    });
    return customer;
  } catch (error) {
    console.error('Error creating Stripe customer:', error);
    throw error;
  }
};

export const updateStripeCustomerMetadata = async (customerId: string, userId: string) => {
  try {
    const customer = await stripe.customers.update(customerId, {
      metadata: {
        firebaseUserId: userId,
      },
    });
    return customer;
  } catch (error) {
    console.error('Error updating Stripe customer metadata:', error);
    throw error;
  }
};

export const syncMissingUserIds = async () => {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    
    let updatedCount = 0;
    const updates = snapshot.docs.map(async (userDoc) => {
      const userData = userDoc.data();
      if (userData.stripeCustomerId) {
        try {
          const customer = await stripe.customers.retrieve(userData.stripeCustomerId);
          if (customer && !customer.deleted && !customer.metadata.firebaseUserId) {
            await updateStripeCustomerMetadata(userData.stripeCustomerId, userDoc.id);
            updatedCount++;
          }
        } catch (error) {
          console.error(`Error updating customer ${userData.stripeCustomerId}:`, error);
        }
      }
    });
    
    await Promise.all(updates);
  } catch (error) {
    console.error('Error syncing missing user IDs:', error);
    throw error;
  }
};

export const cancelSubscription = async (userId: string) => {
  try {
    
    if (!userId) {
      throw new Error('User ID is required');
    }

    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const { stripeCustomerId } = userDoc.data();
    if (!stripeCustomerId) {
      throw new Error('No Stripe customer ID associated with this account');
    }

    // Get customer's subscriptions
    const subscriptions = await stripe.subscriptions.list({
      customer: stripeCustomerId,
      status: 'active',
      limit: 1
    });

    if (subscriptions.data.length === 0) {
      throw new Error('No active subscription found for this account');
    }

    // Cancel the subscription
    const subscription = await stripe.subscriptions.update(
      subscriptions.data[0].id,
      { cancel_at_period_end: true }
    );

    // Update user document
    await updateDoc(userRef, {
      subscriptionStatus: 'canceling',
      subscriptionCanceledAt: new Date(),
      subscriptionEndDate: new Date(subscription.current_period_end * 1000)
    });

    return subscription;
  } catch (error) {
    console.error('Error canceling subscription:', {
      error,
      userId,
      errorMessage: error instanceof Error ? error.message : 'Unknown error'
    });
    throw error;
  }
};

export default stripe;