import React, { useState, useEffect } from 'react';
import { X, Plus, Minus, ArrowLeft, ArrowRight } from 'lucide-react';
import { usePlacesAutocomplete } from '../hooks/usePlacesAutocomplete';

interface MultiDestinationModalProps {
  isOpen: boolean;
  onClose: () => void;
  primaryDestination: string;
  onContinue: (destinations: string[]) => void;
}

export default function MultiDestinationModal({
  isOpen,
  onClose,
  primaryDestination,
  onContinue
}: MultiDestinationModalProps) {
  const [additionalDestinations, setAdditionalDestinations] = useState<string[]>([]);
  const [showPredictions, setShowPredictions] = useState<number | null>(null);
  const { predictions, loading, getPlacePredictions } = usePlacesAutocomplete();
  const [hasInteracted, setHasInteracted] = useState(false);
  const [step, setStep] = useState<'initial' | 'destinations'>('initial');

  const handleInputChange = (index: number, value: string) => {
    setHasInteracted(true);
    const newDestinations = [...additionalDestinations];
    newDestinations[index] = value;
    setAdditionalDestinations(newDestinations);
    getPlacePredictions(value);
    setShowPredictions(index);
  };

  const handlePredictionClick = (index: number, prediction: google.maps.places.AutocompletePrediction) => {
    const newDestinations = [...additionalDestinations];
    newDestinations[index] = prediction.description;
    setAdditionalDestinations(newDestinations);
    setShowPredictions(null);
  };

  const addDestination = () => {
    if (additionalDestinations.length < 3) {
      setAdditionalDestinations([...additionalDestinations, '']);
    }
  };

  const removeDestination = (index: number) => {
    const newDestinations = additionalDestinations.filter((_, i) => i !== index);
    setAdditionalDestinations(newDestinations);
  };

  const handleContinue = () => {
    if (step === 'initial') {
      setStep('destinations');
      setAdditionalDestinations(['']);
      return;
    }
    
    // Validate all destinations are filled
    const allDestinationsFilled = additionalDestinations.every(dest => dest.trim() !== '');
    if (!allDestinationsFilled) {
      return; // Don't proceed if any destination is empty
    }

    const validDestinations = additionalDestinations.filter(dest => dest.trim() !== '');
    setHasInteracted(true);
    onContinue([primaryDestination, ...validDestinations]);
  };

  const handleBack = () => {
    setStep('initial');
    setAdditionalDestinations([]);
    setShowPredictions(null);
  };

  const handleNo = () => {
    setHasInteracted(true);
    onContinue([primaryDestination]);
  };

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      if (hasInteracted) {
        setStep('initial');
        setAdditionalDestinations([]);
        setShowPredictions(null);
        setHasInteracted(false);
      }
    }
  }, [isOpen, hasInteracted]);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl p-8 relative animate-slide-up">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
          Add More Destinations
        </h2>

        <p className="text-gray-600 mb-8">
          Do you want to add another destination to your trip besides {primaryDestination}?
        </p>

        <div className="space-y-6">
          {step === 'destinations' && additionalDestinations.map((destination, index) => (
            <div key={index} className="relative">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={destination}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter destination"
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                />
                <button
                  onClick={() => removeDestination(index)}
                  className="p-2 text-gray-400 hover:text-gray-600"
                  aria-label="Remove destination"
                >
                  <Minus size={24} />
                </button>
              </div>

              {/* Predictions Dropdown */}
              {showPredictions === index && destination.length > 0 && (
                <div className="absolute w-full mt-2 bg-white rounded-3xl shadow-lg overflow-hidden z-10 border border-gray-100">
                  {loading ? (
                    <div className="p-4 text-center text-gray-500">
                      <div className="inline-block animate-spin rounded-full h-6 w-6 border-2 border-[#E53E3E] border-t-transparent"></div>
                    </div>
                  ) : predictions.length > 0 ? (
                    <ul className="max-h-64 overflow-y-auto">
                      {predictions.map((prediction) => (
                        <li
                          key={prediction.place_id}
                          onClick={() => handlePredictionClick(index, prediction)}
                          className="px-6 py-3 hover:bg-gray-50 cursor-pointer transition-colors"
                        >
                          <div className="font-medium text-gray-800">
                            {prediction.structured_formatting.main_text}
                          </div>
                          <div className="text-sm text-gray-500">
                            {prediction.structured_formatting.secondary_text}
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="p-4 text-center text-gray-500">
                      No cities found
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}

          {additionalDestinations.length < 3 && 
            step === 'destinations' &&
            additionalDestinations.length > 0 &&
            additionalDestinations[additionalDestinations.length - 1]?.trim() !== '' && (
            <button
              onClick={addDestination}
              className="flex items-center gap-2 px-6 py-2 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Plus size={20} />
              Add another destination
            </button>
          )}

          {additionalDestinations.length >= 3 && (
            <p className="text-sm text-gray-500">
              Maximum of 3 additional destinations reached
            </p>
          )}
        </div>

        <div className="flex justify-between mt-8">
          {step === 'initial' ? (
            <>
              <button
                onClick={handleNo}
                className="px-6 py-2 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors"
              >
                No, continue with {primaryDestination}
              </button>
              <button
                onClick={handleContinue}
                className="px-6 py-2 border border-gray-300 rounded-full hover:bg-gray-50 transition-colors"
              >
                Add more destinations
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleBack}
                className="flex items-center gap-2 px-6 py-2 border border-gray-300 rounded-full hover:bg-gray-50 transition-colors"
              >
                <ArrowLeft size={20} />
                Back
              </button>
              <button
                onClick={handleContinue}
                disabled={!additionalDestinations.every(dest => dest.trim() !== '')}
                className="flex items-center gap-2 px-6 py-2 bg-[#E53E3E] text-white rounded-full hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
                <ArrowRight size={20} />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}