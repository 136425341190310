import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  addDoc,
  setDoc,
  doc, 
  writeBatch, 
  Timestamp, 
  enableIndexedDbPersistence,
  query,
  where,
  limit,
  getDocs
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { createStripeCustomer } from './stripe';

const firebaseConfig = {
  apiKey: "AIzaSyCqy8dmOcqeHYYWsUu4yE29axTKPQngTnM",
  authDomain: "eztravelplan-1623f.firebaseapp.com",
  projectId: "eztravelplan-1623f",
  storageBucket: "eztravelplan-1623f.firebasestorage.app",
  messagingSenderId: "171086623509",
  appId: "1:171086623509:web:5396a36951c423638eef1c",
  measurementId: "G-GTMQGHEDW5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable offline persistence
const enablePersistence = async () => {
  try {
    await enableIndexedDbPersistence(db);
  } catch (err: any) {
    if (err?.code === 'failed-precondition') {
      // Silently handle multiple tabs case
    } else if (err?.code === 'unimplemented') {
      console.warn('Browser does not support persistence');
    } else {
      console.error('Error enabling persistence:', err);
    }
  }
};

// Only enable persistence if this is the first tab
if (!document.hidden) {
  enablePersistence();
}

export const analytics = getAnalytics(app);

export default app;

// Helper function to create a new user document
export async function createUserDocument(userId: string, email: string, name?: string) {
  try {
    // Create Stripe customer first
    const stripeCustomer = await createStripeCustomer(userId, email, name);

    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      email,
      name: name || null,
      stripeCustomerId: stripeCustomer.id,
      createdAt: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date())
    });
    return { stripeCustomerId: stripeCustomer.id };
  } catch (error) {
    console.error('Error creating user document with Stripe:', {
      error,
      userId,
      email,
      name
    });
    throw error;
  }
}

// Helper function to save contact form submissions
export async function saveContactSubmission(data: {
  name: string;
  email: string;
  message: string;
  itineraryId?: string | null;
}) {
  try {
    const contactRef = collection(db, 'contact_submissions');
    await addDoc(contactRef, {
      ...data,
      createdAt: Timestamp.fromDate(new Date()),
      status: 'new'
    });
    return true;
  } catch (error) {
    console.error('Error saving contact submission:', error);
    throw error;
  }
}
// Helper function to initialize user's itineraries
export async function addSampleItineraries(userId: string) {
  try {
    // Check if user already has itineraries
    const itinerariesRef = collection(db, `users/${userId}/itineraries`);
    const existingQuery = query(
      itinerariesRef,
      limit(1)
    );
    const existingDocs = await getDocs(existingQuery);
    
    if (!existingDocs.empty) {
      return;
    }

    const batch = writeBatch(db);

    const itineraries = [
      {
        title: 'Weekend in Paris',
        destination: 'Paris, France',
        createdAt: Timestamp.fromDate(new Date()),
        startDate: Timestamp.fromDate(new Date(2024, 5, 15)),
        endDate: Timestamp.fromDate(new Date(2024, 5, 17))
      },
      {
        title: 'Tokyo Adventure',
        destination: 'Tokyo, Japan',
        createdAt: Timestamp.fromDate(new Date()),
        startDate: Timestamp.fromDate(new Date(2024, 7, 1)),
        endDate: Timestamp.fromDate(new Date(2024, 7, 14))
      }
    ];

    itineraries.forEach((itinerary) => {
      const docRef = doc(itinerariesRef);
      batch.set(docRef, itinerary);
    });

    await batch.commit();
  } catch (error) {
    console.error('Error adding sample itineraries:', error);
    throw error;
  }
}