import React, { useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

export default function DataProtection() {
  const lastUpdated = '2024-03-20';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-[#2C3E50] mb-8">Data Protection Policy</h1>
      <p className="text-sm text-gray-600 mb-8">Last Updated: {lastUpdated}</p>

      <div className="prose prose-lg max-w-none">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">1. Data Security Measures</h2>
          <p className="text-gray-600 mb-4">We implement the following security measures:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>End-to-end encryption for data transmission</li>
            <li>Regular security audits and assessments</li>
            <li>Access control and authentication</li>
            <li>Secure data storage and backup</li>
            <li>Employee security training</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">2. Data Retention</h2>
          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">2.1 Retention Periods</h3>
          <p className="text-gray-600 mb-4">We retain different types of data for the following periods:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Account information: Duration of account activity plus 2 years</li>
            <li>Travel itineraries: 5 years from creation</li>
            <li>Payment information: As required by law</li>
            <li>Communication records: 2 years</li>
          </ul>

          <h3 className="text-xl font-semibold text-[#2C3E50] mb-3">2.2 Data Deletion</h3>
          <p className="text-gray-600 mb-4">
            Data is securely deleted after the retention period expires or upon user request, subject to legal requirements.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">3. User Data Access Rights</h2>
          <p className="text-gray-600 mb-4">Users can:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Request a copy of their personal data</li>
            <li>Update or correct their information</li>
            <li>Request data deletion</li>
            <li>Export data in a machine-readable format</li>
            <li>Object to certain data processing</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">4. GDPR Compliance</h2>
          <p className="text-gray-600 mb-4">We ensure GDPR compliance through:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Lawful basis for processing</li>
            <li>Data minimization principles</li>
            <li>Privacy by design and default</li>
            <li>Regular impact assessments</li>
            <li>Appointment of Data Protection Officer</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">5. CCPA Compliance</h2>
          <p className="text-gray-600 mb-4">For California residents, we provide:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Right to know about personal information collected</li>
            <li>Right to delete personal information</li>
            <li>Right to opt-out of data sales</li>
            <li>Right to non-discrimination</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">6. Data Breach Procedures</h2>
          <p className="text-gray-600 mb-4">In case of a data breach:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Immediate assessment and containment</li>
            <li>Notification to affected users within 72 hours</li>
            <li>Reporting to relevant authorities</li>
            <li>Investigation and preventive measures</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-[#2C3E50] mb-4">7. International Data Transfers</h2>
          <p className="text-gray-600 mb-4">For international data transfers, we:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Use EU-approved Standard Contractual Clauses</li>
            <li>Ensure adequate data protection measures</li>
            <li>Monitor international compliance requirements</li>
            <li>Maintain transparency about data locations</li>
          </ul>
        </section>
      </div>

      <button
        onClick={scrollToTop}
        className="fixed bottom-8 right-8 bg-[#4A90E2] text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
        aria-label="Scroll to top"
      >
        <ArrowUp size={24} />
      </button>
    </div>
  );
}