import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    image: 'https://images.unsplash.com/photo-1526772662000-3f88f10405ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80',
    destination: 'Bali, Indonesia',
    name: 'Sarah',
    text: 'Every recommendation was spot on!'
  },
  {
    image: 'https://images.unsplash.com/photo-1523906834658-6e24ef2386f9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1983&q=80',
    destination: 'Venice, Italy',
    name: 'Michael',
    text: 'Found hidden gems I never would have discovered on my own.',
  },
  {
    image: 'https://images.unsplash.com/photo-1528164344705-47542687000d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2092&q=80',
    destination: 'Tokyo, Japan',
    name: 'Emily',
    text: 'The local food recommendations were absolutely incredible!',
  },
  {
    image: 'https://images.unsplash.com/photo-1590523277543-a94d2e4eb00b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2096&q=80',
    destination: 'Santorini, Greece',
    name: 'David',
    text: 'Perfect sunset spots and amazing local experiences. Unforgettable!',
  },
  {
    image: 'https://images.unsplash.com/photo-1531572753322-ad063cecc140?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2076&q=80',
    destination: 'Barcelona, Spain',
    name: 'Sofia',
    text: 'The architecture tour suggestions were fantastic. Great cultural insights!',
  },
  {
    image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80',
    destination: 'Paris, France',
    name: 'James',
    text: 'Skipped all the tourist traps and had an authentic Parisian experience.',
  },
  {
    image: 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
    destination: 'Dubai, UAE',
    name: 'Aisha',
    text: 'From desert safaris to rooftop dining, every experience was meticulously planned!',
  },
  {
    image: 'https://images.unsplash.com/photo-1573790387438-4da905039392?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2025&q=80',
    destination: 'Queenstown, New Zealand',
    name: 'Lucas',
    text: 'The adventure activities recommendations were perfect for our adrenaline-seeking family!',
  },
  {
    image: 'https://images.unsplash.com/photo-1518509562904-e7ef99cdcc86?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80',
    destination: 'Cape Town, South Africa',
    name: 'Zara',
    text: 'From Table Mountain hikes to wine tasting, each day was perfectly balanced with activities.',
  }
];

export default function CustomerShowcase() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % Math.ceil(testimonials.length / 3));
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPlaying]);

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-[#2C3E50] mb-12">
          Traveler Stories
        </h2>
        
        <div className="relative"
             onMouseEnter={() => setIsPlaying(false)}
             onMouseLeave={() => setIsPlaying(true)}>
          <div className="overflow-hidden py-8">
            <div className="flex transition-transform duration-500 ease-in-out"
                 style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
              {testimonials.map((item, index) => (
                <div key={index} className="w-full md:w-1/3 flex-shrink-0 px-6">
                  <div className="bg-white rounded-3xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-110 hover:z-10">
                    <div className="relative h-72">
                      <img
                        src={item.image}
                        alt={item.destination}
                        className="w-full h-full object-cover transition-transform duration-300"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
                        <h3 className="text-white font-semibold">{item.destination}</h3>
                        <p className="text-white/90 text-sm">{item.name}</p>
                      </div>
                    </div>
                    <div className="p-8">
                      <p className="text-gray-600 italic text-base leading-relaxed min-h-[4rem]">&ldquo;{item.text}&rdquo;</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <button
            onClick={() => setCurrentSlide((prev) => Math.max(prev - 1, 0))} 
            className="absolute left-8 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-3 rounded-full shadow-lg transition-colors z-20"
            disabled={currentSlide === 0}
          >
            <ChevronLeft size={24} />
          </button>
          
          <button
            onClick={() => setCurrentSlide((prev) => Math.min(prev + 1, Math.ceil(testimonials.length / 3) - 1))}
            className="absolute right-8 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-3 rounded-full shadow-lg transition-colors z-20"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}