import React, { useState, useEffect } from 'react';
import { MessageSquare, X, Send } from 'lucide-react';
import { saveContactSubmission } from '../lib/firebase';

interface ContactFormModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedItinerary: string | null;
  setSelectedItinerary: (id: string | null) => void;
}

export default function ContactFormModal({ 
  isOpen, 
  setIsOpen, 
  selectedItinerary,
  setSelectedItinerary 
}: ContactFormModalProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [honeypot, setHoneypot] = useState('');
  const [formToken, setFormToken] = useState('');

  useEffect(() => {
    // Generate CSRF token on component mount
    setFormToken(Math.random().toString(36).substring(2));
  }, []);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    // Name validation (letters only, min 2 chars)
    if (!formData.name.match(/^[A-Za-z\s]{2,}$/)) {
      newErrors.name = 'Name must contain only letters and be at least 2 characters long';
    }
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Message validation (min 10 chars)
    if (formData.message.length < 10) {
      newErrors.message = 'Message must be at least 10 characters long';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    if (honeypot) return; // If honeypot is filled, silently reject
    
    setIsSubmitting(true);
    
    try {
      await saveContactSubmission({
        ...formData,
        itineraryId: selectedItinerary
      });
      
      setShowSuccess(true);
      setTimeout(() => {
        setIsOpen(false);
        setFormData({ name: '', email: '', message: '' });
        setSelectedItinerary(null);
        setShowSuccess(false);
      }, 2000);
      
    } catch (error) {
      setErrors({
        ...errors,
        submit: 'Failed to send message. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md p-6 relative animate-slide-up">
        <button
          onClick={() => {
            setIsOpen(false);
            setErrors({});
            setSelectedItinerary(null);
          }}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          aria-label="Close Contact Form"
        >
          <X size={20} />
        </button>

        <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
          {selectedItinerary ? 'Request Travel Consultancy' : 'Contact Us'}
        </h2>

        {showSuccess && (
          <div className="mb-6 p-4 bg-green-50 text-green-600 rounded-3xl text-center">
            Message sent successfully!
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, name: e.target.value }));
                if (errors.name) setErrors(prev => ({ ...prev, name: '' }));
              }}
              className={`w-full px-4 py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              }`}
              required
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-600">{errors.name}</p>
            )}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, email: e.target.value }));
                if (errors.email) setErrors(prev => ({ ...prev, email: '' }));
              }}
              className={`w-full px-4 py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
              required
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">{errors.email}</p>
            )}
          </div>

          {/* Honeypot field - hidden from users */}
          <div className="hidden">
            <label htmlFor="website">Website</label>
            <input
              type="text"
              id="website"
              name="website"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
              tabIndex={-1}
              autoComplete="off"
            />
          </div>

          {/* Hidden CSRF token */}
          <input type="hidden" name="_csrf" value={formToken} />

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
              Message
            </label>
            <textarea
              id="message"
              value={formData.message}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, message: e.target.value }));
                if (errors.message) setErrors(prev => ({ ...prev, message: '' }));
              }}
              rows={4}
              className={`w-full px-4 py-2 border rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent resize-none ${
                errors.message ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder={selectedItinerary 
                ? "Tell us about your travel needs and how we can help with your itinerary..."
                : "How can we help you?"}
              required
            />
            {errors.message && (
              <p className="mt-1 text-sm text-red-600">{errors.message}</p>
            )}
          </div>

          {errors.submit && (
            <div className="p-3 bg-red-50 text-red-600 rounded-3xl text-sm">
              {errors.submit}
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-[#E53E3E] text-white py-2 px-4 rounded-3xl hover:bg-red-600 transition-colors flex items-center justify-center gap-2"
          >
            <Send size={20} />
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
}