import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: 'How much does the service cost?',
    answer: 'Our premium itinerary planning service starts at $29.95 per day on the itinerary, which include premium features like real-time updates and 24/7 support. Our annual subscription with unlimited access to AI itineraries is $49.95'
  },
  {
    question: 'How far in advance should I plan?',
    answer: 'For the best experience, we recommend planning at least 2-3 months ahead. This allows time to secure the best rates and availability for accommodations and activities.'
  },
  {
    question: 'Can I modify my itinerary?',
    answer: 'Only if you have a Premium itinerary. Your itinerary is fully customizable by just contacting us. We will help readjust the schedule and make new recommendations based on your changes. All modifications are instantly synced to your list.'
  },
  {
    question: 'What happens if I need support?',
    answer: 'Our customer support team is available via email. Premium users get priority support with response times under 24 hours.'
  },
  {
    question: 'Are the recommendations verified?',
    answer: 'Yes! All recommendations are verified through multiple sources including recent traveler reviews, local experts, and our AI analysis of current trends and seasonal factors.'
  }
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section id="faq" className="py-16 bg-white scroll-mt-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-[#2C3E50] mb-12">
          Frequently Asked Questions
        </h2>
        
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="border border-gray-200 rounded-3xl overflow-hidden"
            >
              <button
                className="w-full px-6 py-4 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="font-medium text-[#2C3E50] text-left">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <ChevronUp className="text-[#4A90E2] flex-shrink-0" />
                ) : (
                  <ChevronDown className="text-gray-400 flex-shrink-0" />
                )}
              </button>
              
              <div 
                className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
                  openIndex === index ? 'max-h-48 py-4' : 'max-h-0'
                }`}
              >
                <p className="text-gray-600">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}