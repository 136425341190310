import React, { useState } from 'react';
import { User, LogIn, Menu, X, LogOut, Map } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, addSampleItineraries, createUserDocument } from '../lib/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';

const validatePassword = (password: string) => {
  const minLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumeric = /[0-9]/.test(password);
  
  return {
    minLength,
    hasUpperCase,
    hasLowerCase,
    hasNumeric,
    isValid: minLength && hasUpperCase && hasLowerCase && hasNumeric
  };
};

interface AuthHeaderProps {
  showAuthModal: 'signin' | 'signup' | null;
  setShowAuthModal: (value: 'signin' | 'signup' | null) => void;
  showMultiDestModal: boolean;
  setShowMultiDestModal: (value: boolean) => void;
}

export default function AuthHeader({ 
  showAuthModal, 
  setShowAuthModal,
  showMultiDestModal,
  setShowMultiDestModal
}: AuthHeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [user, setUser] = useState(auth.currentUser);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const passwordValidation = validatePassword(password);
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
      // Wait for navigation to complete before scrolling
      setTimeout(() => {
        document.getElementById('hero-section')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }, 100);
    } else {
      document.getElementById('hero-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      
      if (user) {
        // Check for stored destination after authentication
        const pendingDestination = sessionStorage.getItem('pendingDestination');
        if (pendingDestination) {
          sessionStorage.removeItem('pendingDestination');
          // Trigger destination modal
          setShowMultiDestModal(true);
        }
      }
      
      // Check for return URL after successful authentication
      if (user) {
        const returnUrl = sessionStorage.getItem('returnUrl');
        const showTravelModal = sessionStorage.getItem('showTravelModal');
        const selectedCity = sessionStorage.getItem('selectedCity');
        
        // Clear stored data
        sessionStorage.removeItem('returnUrl');
        sessionStorage.removeItem('showTravelModal');
        sessionStorage.removeItem('selectedCity');
        
        if (returnUrl) {
          navigate(returnUrl);
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    console.log('Starting user registration/login process');

    try {
      if (showAuthModal === 'signup') {
        console.log('Creating new user account');
        await createUserWithEmailAndPassword(auth, email, password);
        
        console.log('User account created, setting up user document and Stripe customer');
        if (auth.currentUser) {
          const { stripeCustomerId } = await createUserDocument(auth.currentUser.uid, email, name);
          console.log('User document and Stripe customer created:', { stripeCustomerId });
          
          console.log('Adding sample itineraries');
          await addSampleItineraries(auth.currentUser.uid);
        }
        
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
        console.log('Registration process completed successfully');
      } else {
        console.log('Signing in existing user');
        await signInWithEmailAndPassword(auth, email, password);
        console.log('Sign in successful');
      }
      setShowAuthModal(null);
    } catch (err) {
      console.error('Error during authentication:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };


  return (
    <header className="sticky top-0 bg-white shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <button onClick={handleLogoClick} className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center overflow-hidden border-2">
                <img 
                  src="/logo.png" 
                  alt="EZTravelPlan Logo" 
                  className="w-8 h-8 object-contain"
                />
              </div>
              <span className="text-2xl font-bold text-[#2C3E50] hover:text-[#E53E3E] transition-colors">
                EZTravelPlan
              </span>
            </button>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-8">
            {!user && (
              <>
                <a href="#features" className="text-gray-600 hover:text-[#E53E3E]">Features</a>
                <a href="#pricing" className="text-gray-600 hover:text-[#E53E3E]">Pricing</a>
                <a href="#faq" className="text-gray-600 hover:text-[#E53E3E]">FAQ</a>
                <button
                  onClick={() => setShowAuthModal('signin')}
                  className="text-[#E53E3E] hover:text-red-600 px-4 py-2 rounded-3xl flex items-center gap-2"
                >
                  <LogIn size={20} />
                  Sign In
                </button>
                <button
                  onClick={() => setShowAuthModal('signup')}
                  className="bg-[#E53E3E] text-white px-4 py-2 rounded-3xl hover:bg-red-600 transition-colors flex items-center gap-2"
                >
                  <User size={20} />
                  Sign Up
                </button>
              </>
            )}
            {user && (
              <>
                <button
                  onClick={() => navigate('/my-itineraries')}
                  className="text-[#E53E3E] hover:text-red-600 px-4 py-2 rounded-3xl flex items-center gap-2"
                >
                  <Map size={20} />
                  My Itineraries
                </button>
                <button
                  onClick={handleSignOut}
                  className="bg-[#E53E3E] text-white px-4 py-2 rounded-3xl hover:bg-red-600 transition-colors flex items-center gap-2"
                >
                  <LogOut size={20} />
                  Sign Out
                </button>
              </>
            )}
          </nav>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-md text-gray-400 hover:text-gray-500"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {!user && (
              <>
                <a
                  href="#features"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#4A90E2] hover:bg-gray-50"
                >
                  Features
                </a>
                <a
                  href="#pricing"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#4A90E2] hover:bg-gray-50"
                >
                  Pricing
                </a>
                <a
                  href="#faq"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-[#4A90E2] hover:bg-gray-50"
                >
                  FAQ
                </a>
                <button
                  onClick={() => {
                    setShowAuthModal('signin');
                    setIsMenuOpen(false);
                  }}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-[#E53E3E] hover:bg-gray-50"
                >
                  Sign In
                </button>
                <button
                  onClick={() => {
                    setShowAuthModal('signup');
                    setIsMenuOpen(false);
                  }}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-[#E53E3E] hover:bg-gray-50"
                >
                  Sign Up
                </button>
              </>
            )}
            {user && (
              <>
                <button
                  onClick={() => navigate('/my-itineraries')}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-[#E53E3E] hover:bg-gray-50"
                >
                  My Itineraries
                </button>
                <button
                  onClick={handleSignOut}
                  className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-[#E53E3E] hover:bg-gray-50"
                >
                  Sign Out
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Success Message */}
      {showSuccessMessage && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-green-100 text-green-800 px-6 py-3 rounded-3xl shadow-lg animate-slide-up">
          Account created successfully!
        </div>
      )}

      {/* Auth Modal */}
      {showAuthModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-md p-6 relative animate-slide-up">
            <button
              onClick={() => setShowAuthModal(null)}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
            >
              <X size={20} />
            </button>

            <h2 className="text-2xl font-bold text-[#2C3E50] mb-6">
              {showAuthModal === 'signin' ? 'Sign In' : 'Create Account'}
            </h2>

            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-3xl text-sm">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              {showAuthModal === 'signup' && (
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                    required
                  />
                </div>
              )}

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                  {showAuthModal === 'signup' && (
                    <span className="text-xs text-gray-500 ml-1">
                      (min. 8 characters)
                    </span>
                  )}
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-3xl focus:ring-2 focus:ring-[#E53E3E] focus:border-transparent"
                  required
                />
                {showAuthModal === 'signup' && (
                  <div className="mt-2 space-y-1">
                    <p className={`text-xs ${passwordValidation.minLength ? 'text-green-600' : 'text-gray-500'}`}>
                      • Minimum 8 characters
                    </p>
                    <p className={`text-xs ${passwordValidation.hasUpperCase ? 'text-green-600' : 'text-gray-500'}`}>
                      • At least one uppercase letter
                    </p>
                    <p className={`text-xs ${passwordValidation.hasLowerCase ? 'text-green-600' : 'text-gray-500'}`}>
                      • At least one lowercase letter
                    </p>
                    <p className={`text-xs ${passwordValidation.hasNumeric ? 'text-green-600' : 'text-gray-500'}`}>
                      • At least one number
                    </p>
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={(showAuthModal === 'signup' && !passwordValidation.isValid) || loading}
                className="w-full bg-[#E53E3E] text-white py-2 px-4 rounded-3xl hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Please wait...' : (showAuthModal === 'signin' ? 'Sign In' : 'Create Account')}
              </button>
            </form>

            <div className="mt-4 text-center text-sm text-gray-600">
              {showAuthModal === 'signin' ? (
                <>
                  Don't have an account?{' '}
                  <button
                    onClick={() => setShowAuthModal('signup')}
                    className="text-[#E53E3E] hover:text-red-600"
                  >
                    Sign up
                  </button>
                </>
              ) : (
                <>
                  Already have an account?{' '}
                  <button
                    onClick={() => setShowAuthModal('signin')}
                    className="text-[#E53E3E] hover:text-red-600"
                  >
                    Sign in
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}